import { DEFAULT_FILTER } from './user';
import { AppUser } from 'Src/views/App/App';
import axios from 'axios';
import { DEFAULT_LIMIT, DEFAULT_SKIP } from 'Src/consts/table';
import { Count } from '../../../models/count';
import { SchoolHouse } from '../../../models/house';

/** USER TAG TABLE */
export function getHouses(user: AppUser, filter = DEFAULT_FILTER): Promise<SchoolHouse[]> {
  const { roleSessionKey: key, activeSchoolId } = user;

  const config = {
    headers: { usid: key },
    params: {
      filter
    }
  };

  return axios.get<SchoolHouse[]>(`${window.apiBase}/i/schools/${activeSchoolId}/houses`, config).then(response => {
    return response.data;
  });
}

export function getAllHouses(user: AppUser, where?): Promise<SchoolHouse[]> {
  const { roleSessionKey: key, activeSchoolId } = user;

  let config;

  if (typeof where !== 'undefined') {
    config = {
      headers: { usid: key },
      params: {
        filter: {
          where: {
            ...where
          },
          limit: DEFAULT_LIMIT,
          skip: DEFAULT_SKIP
        }
      }
    };
  } else {
    config = {
      headers: { usid: key },
      params: {
        filter: {
          limit: DEFAULT_LIMIT,
          skip: DEFAULT_SKIP
        }
      }
    };
  }

  return axios.get<SchoolHouse[]>(`${window.apiBase}/i/schools/${activeSchoolId}/houses`, config).then(response => {
    return response.data;
  });
}

export function getHousesCount(user: AppUser, where?): Promise<Count> {
  const { roleSessionKey: key, activeSchoolId } = user;

  let config;

  if (typeof where !== 'undefined') {
    config = {
      headers: { usid: key },
      params: {
        filter: {
          where: where
        }
      }
    };
  } else {
    config = {
      headers: { usid: key }
    };
  }

  return axios.get<Count>(`${window.apiBase}/i/schools/${activeSchoolId}/houses/count`, config).then(response => {
    return response.data;
  });
}

export function createHouse(user: AppUser, data) {
  const { roleSessionKey: key, activeSchoolId } = user;

  const config = {
    headers: { usid: key }
  };

  return axios.post(`${window.apiBase}/i/schools/${activeSchoolId}/houses`, data, config).then(response => {
    return response.data;
  });
}

export function updateHouse(user: AppUser, data, houseId: string) {
  const { roleSessionKey: key, activeSchoolId } = user;

  const config = {
    headers: { usid: key }
  };

  return axios.put(`${window.apiBase}/i/schools/${activeSchoolId}/houses/${houseId}`, data, config).then(response => {
    return response.data;
  });
}

export function deleteHouse(user: AppUser, houseId) {
  const { roleSessionKey: key, activeSchoolId } = user;

  const config = {
    headers: { usid: key }
  };

  return axios.delete(`${window.apiBase}/i/schools/${activeSchoolId}/houses/${houseId}`, config).then(response => {
    return response.data;
  });
}

export function checkAvailabilityHouseName(user: AppUser, data) {
  const { roleSessionKey: key, activeSchoolId } = user;
  const config = {
    headers: { usid: key }
  };
  return axios.post(`${window.apiBase}/i/schools/${activeSchoolId}/houses/check`, data, config).then(response => {
    return response.data;
  });
}