import * as React from 'react';
import * as propz from 'propz';
import { Field, Form, Formik } from 'formik';
import { Tournament } from 'Src/models/tournament';
import { Button } from 'Src/components/Button/Button';
import { AppUser } from 'Src/views/App/App';
import { AGE_GROUPS } from 'Src/consts/school';
import { GENDER, SPORT_GENDER_SERVER_TO_CLIENT_MAPPING } from 'Src/consts/common';
import {
  isIndividualTournament,
  isMiniTeamTournament,
  isSingleDoubleTournament,
  isTeamTournament
} from 'Src/helpers/tournament/tournament';
import { Switch } from 'Src/components/Switch/Switch';
import { INDIVIDUAL_TOURNAMENT_MAXIMUM_NUMBER_TEAMS_SCHOOL } from '../../../../../consts/tournament';

interface Props {
  user: AppUser;
  tournament: Tournament;
  onCancel: () => void;
  onSubmit: (data: any) => void;
}

export const TournamentAutoEnrollmentForm: React.FunctionComponent<Props> = props => {
  const { tournament, onSubmit, user } = props;

  const tournamentAgesGroupName = propz.get(user, ['activeSchool', 'ageGroupsNaming'], []) as any;

  const getAutoEnrollmentTeamsSettingsFormFields = () => {
    const isMixedTeamsAvailable = propz.get(tournament, ['isMixedTeamsAvailable']);
    const ages = propz.get(tournament, ['ages'], []);
    const gender = propz.get(tournament, ['gender'], GENDER.MIXED);

    const isGenderMixed = gender === GENDER.MIXED;
    const isGenderMale = gender === GENDER.MALE_ONLY;
    const isGenderFemale = gender === GENDER.FEMALE_ONLY;

    let autoEnrollmentSettingByAgeAndGender = [];

    switch (true) {
      case isGenderMixed && !isMixedTeamsAvailable:
        ages.forEach(age => {
          autoEnrollmentSettingByAgeAndGender.push(getAutoEnrollmentSettingByAgeAndGender(age, GENDER.MALE_ONLY));
          autoEnrollmentSettingByAgeAndGender.push(getAutoEnrollmentSettingByAgeAndGender(age, GENDER.FEMALE_ONLY));
        });
        break;
      case isGenderMixed && isMixedTeamsAvailable:
        ages.forEach(age => {
          autoEnrollmentSettingByAgeAndGender.push(getAutoEnrollmentSettingByAgeAndGender(age, GENDER.MIXED));
        });
        break;
      case isGenderMale:
        ages.forEach(age => {
          autoEnrollmentSettingByAgeAndGender.push(getAutoEnrollmentSettingByAgeAndGender(age, gender));
        });
        break;
      case isGenderFemale:
        ages.forEach(age => {
          autoEnrollmentSettingByAgeAndGender.push(getAutoEnrollmentSettingByAgeAndGender(age, gender));
        });
        break;
    }
    return autoEnrollmentSettingByAgeAndGender;
  };

  const getAutoEnrollmentSettingByAgeAndGender = (age: number, gender: string) => {
    const autoEnrollmentTeamsSettings = propz.get(tournament, ['autoEnrollmentTeamsSettings'], []);

    const setting = autoEnrollmentTeamsSettings.find(autoEnrollmentTeamsSetting => {
      const isAgeEqual = autoEnrollmentTeamsSetting.age === age;
      const isGenderEqual = autoEnrollmentTeamsSetting.gender === gender;

      return isAgeEqual && isGenderEqual;
    });

    const maximumNumberTeams = propz.get(setting, ['maximumNumberTeams'], 0);

    return {
      maximumNumberTeams,
      age,
      gender
    };
  };

  const onIsMaximumNumberTeamsChange = (e, index, values) => {
    const autoEnrollmentTeamsSettings = [...values.autoEnrollmentTeamsSettings];
    autoEnrollmentTeamsSettings[index].maximumNumberTeams = Number(e.target.value);
    return autoEnrollmentTeamsSettings;
  };

  const {
    name: tournamentName,
    maximumNumberTeamsOfSchool,
    isReadOnly,
    isAutoEnrollmentTeams,
    isAutoEnrollmentToWaitingList
  } = tournament;

  const isTeamSingleDoubleOrMiniTeamTournament =
    isTeamTournament(tournament) || isSingleDoubleTournament(tournament) || isMiniTeamTournament(tournament);

  const autoEnrollmentForm = {
    autoEnrollmentTeamsSettings: getAutoEnrollmentTeamsSettingsFormFields() || [],
    maximumNumberTeamsOfSchool: isIndividualTournament(tournament)
      ? INDIVIDUAL_TOURNAMENT_MAXIMUM_NUMBER_TEAMS_SCHOOL
      : maximumNumberTeamsOfSchool,
    isReadOnly: isReadOnly,
    isAutoEnrollmentTeams: isAutoEnrollmentTeams,
    isAutoEnrollmentToWaitingList: isAutoEnrollmentToWaitingList,
    autoEnrollmentSettings: propz.get(tournament, ['autoEnrollmentTeamsSettings', 0, 'maximumNumberTeams'], 0)
  };

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-md-12">
          <Formik
            initialValues={autoEnrollmentForm}
            onSubmit={values => {
              onSubmit(values);
            }}
            render={({ values, setFieldValue }) => (
              <Form>
                <Field
                  name="isReadOnly"
                  render={({ field }) => <Switch {...field} text={'Schools in read-only mode'} customClass="mb-3" />}
                />
                <Field
                  name="isAutoEnrollmentTeams"
                  render={({ field }) => (
                    <Switch
                      {...field}
                      onChange={event => {
                        const value = event.target.checked;
                        if (!value) {
                          setFieldValue('isAutoEnrollmentToWaitingList', value);
                        }
                        setFieldValue('isAutoEnrollmentTeams', value);
                      }}
                      text={'Auto enrollment'}
                      customClass="mb-3"
                    />
                  )}
                />
                <Field
                  name="isAutoEnrollmentToWaitingList"
                  render={({ field }) => (
                    <Switch
                      {...field}
                      disabled={!values.isAutoEnrollmentTeams}
                      text={'Enrollment onto the waiting list only'}
                      customClass="mb-3"
                    />
                  )}
                />
                {isTeamSingleDoubleOrMiniTeamTournament && (
                  <div>Specify the maximum number of teams from one school taking part in {`${tournamentName}`}:</div>
                )}
                {isTeamSingleDoubleOrMiniTeamTournament && (
                  <div className="form-group">
                    <label>Maximum number teams from one school (1-5, 0 - unlimited)</label>
                    <Field type="number" name="maximumNumberTeamsOfSchool" min="0" max="5" className="form-control" />
                  </div>
                )}

                {isIndividualTournament(tournament) && (
                  <>
                    <div className="">Specify the maximum number of teams taking part in {tournamentName}:</div>

                    <div key={'autoEnrollmentSettings'} className="form-group">
                      <div className="form-group">
                        <label>Maximum number of teams</label>
                        <Field type="number" min="0" name="autoEnrollmentSettings" className="form-control" />
                      </div>
                    </div>
                  </>
                )}

                {isTeamSingleDoubleOrMiniTeamTournament &&
                  values.autoEnrollmentTeamsSettings.map(({ age, gender, maximumNumberTeams }, index) => {
                    const tournamentAgesGroup = propz.get(AGE_GROUPS, [tournamentAgesGroupName], []);
                    const agesGroupNameLabel = tournamentAgesGroup[age];
                    const genderLabel = SPORT_GENDER_SERVER_TO_CLIENT_MAPPING[gender];
                    return (
                      <div key={'autoEnrollmentTeamsSettings' + index} className="form-group">
                        <div className="form-group">
                          <label>{`${agesGroupNameLabel}, ${genderLabel}`}</label>
                          <Field
                            type="number"
                            min="0"
                            value={maximumNumberTeams}
                            onChange={event => {
                              setFieldValue(
                                'autoEnrollmentTeamsSettings',
                                onIsMaximumNumberTeamsChange(event, index, values)
                              );
                            }}
                            className="form-control"
                          />
                        </div>
                      </div>
                    );
                  })}

                <Button onClick={props.onCancel} text={'Cancel'} customClass={'mt-3 mb-3 mr-3 btn-secondary'} />
                <button type="submit" className="mt-3 mb-3 btn btn-primary">
                  Save
                </button>
              </Form>
            )}
          />
        </div>
      </div>
    </div>
  );
};

TournamentAutoEnrollmentForm.displayName = 'TournamentAutoEnrollmentForm';
