import * as propz from 'propz';
import * as Lazy from 'lazy.js';
import {
  getPlacesAndPostcodes,
  getSchoolClubSports,
  getSchoolForms,
  getSchoolHouses,
  getSchoolSports,
  getSchoolVenues
} from 'Src/helpers/service/admin/user';
import { AppUser } from 'Src/views/App/App';
import { TYPE_OF_PLAYERS } from 'Src/consts/sport';
import { getPaymentAccounts } from 'Src/helpers/service/admin/paymentAccounts';
import { getMergeableUsers } from 'Src/helpers/service/admin/requests';
import { EVENT_VENUE } from 'Src/consts/venue';
import { GENDER } from 'Src/consts/common';
import { USER_GENDER, SPORT_GENDER_TO_USER_GENDER_MAPPING } from 'Src/consts/user';
import { isArraysEqual } from 'Src/helpers/common/common';
import { getSchoolEventCoaches, getSchoolEventStaff } from 'Src/helpers/service/admin/schoolEvents';
import { getSchoolUsers } from 'Src/helpers/service/admin/users';
import { getClubs } from 'Src/helpers/service/admin/clubs';
import { DEFAULT_LIMIT, DEFAULT_SKIP } from 'Src/consts/table';
import { getPhoneRegion } from 'Src/helpers/region/region';
import { getSchoolTeams } from 'Src/helpers/service/admin/schoolTeams';
import { GENDER_TYPE } from 'Src/types/common';
import { getChampionships } from 'Src/helpers/service/adminSU/championshipsTable';
import { getTournamentEventTeams } from 'Src/helpers/service/adminSU/tournamentTeamsTable';
import { getTournamentParticipants } from 'Src/helpers/service/adminSU/tournamentParticipantsTable';
import { getMainGeoSchoolFilterByParams } from '../venue/venue';
import {
  getSchoolFormsPublic,
  getSchoolHousesPublic,
  getSchoolPostcode,
  getSchoolSportsPublic,
  getSchoolsPublic,
  getSchoolTournamentsPublic
} from '../service/public/public';
import { SchoolEvent, Participant } from '../../models/event';
import { getSchoolStudents } from '../service/admin/students';
import { ROLE } from '../../consts/user';
import { Team } from '../../models/team';
import { SchoolForm } from '../../models/form';
import { getCoaches, getStaffs } from '../service/admin/clubs';
import { School } from '../../models/school';
import { getHighLevelTournamentEvents } from 'Src/helpers/service/adminSU/tournamentEventsTable';
import { AGE_GROUPS } from 'Src/consts/school';
import { IndividualTournamentTeamPlayer } from 'Src/models/tournamentTeamPlayer';
import { getSchoolTournamentTeamPlayers } from 'Src/helpers/service/admin/tournamentTeamPlayers';

export function searchFunctionForms(user: AppUser, text: string): Promise<SchoolForm[]> {
  const filter = {
    where: {
      name: {
        like: text || '',
        options: 'i'
      }
    },
    limit: 30,
    skip: DEFAULT_SKIP
  };

  return getSchoolForms(user, filter);
}

export function searchFunctionFormsNinFormIds(user: AppUser, text: string, formIds: string[]): Promise<SchoolForm[]> {
  const filter = {
    where: {
      name: {
        like: text || '',
        options: 'i'
      },
      id: {
        $nin: formIds
      }
    },
    limit: 10,
    skip: DEFAULT_SKIP
  };

  return getSchoolForms(user, filter);
}

export function searchFunctionHouses(user: AppUser, text: string) {
  const filter = {
    where: {
      name: {
        like: text || '',
        options: 'i'
      }
    },
    limit: 10,
    skip: DEFAULT_SKIP
  };

  return getSchoolHouses(user, filter);
}

export function searchFunctionHousesNinHouseIds(user: AppUser, text: string, houseIds: string[]) {
  const filter = {
    where: {
      name: {
        like: text || '',
        options: 'i'
      },
      id: {
        $nin: houseIds
      }
    },
    limit: 10,
    skip: DEFAULT_SKIP
  };

  return getSchoolHouses(user, filter);
}

export function searchFunctionAllHousesNinHouseIds(user: AppUser, text: string, houseIds: string[]) {
  const filter = {
    where: {
      name: {
        like: text || '',
        options: 'i'
      },
      id: {
        $nin: houseIds
      }
    },
    limit: DEFAULT_LIMIT,
    skip: DEFAULT_SKIP
  };

  return getSchoolHouses(user, filter);
}

export function searchFunctionSchoolStudents(user: AppUser, form, house, text: string) {
  let filter;

  if (typeof text === 'undefined' || text === '') {
    filter = {
      limit: 10,
      where: {},
      skip: DEFAULT_SKIP
    };
  } else {
    filter = {
      limit: 10,
      skip: DEFAULT_SKIP,
      where: {
        $or: [{ firstName: { like: text, options: 'i' } }, { lastName: { like: text, options: 'i' } }]
      }
    };
  }

  // form Id is optional so it can be empty
  if (typeof form !== 'undefined') {
    filter.where.formId = { $in: [form.id] };
  }

  // house Id is optional so it can be empty
  if (typeof house !== 'undefined') {
    filter.where.houseId = { $in: [house.id] };
  }

  return getSchoolStudents(user, filter);
}

export function searchFunctionSports(user: AppUser, text: string) {
  const filter = {
    where: {
      name: {
        like: text || '',
        options: 'i'
      }
    },
    limit: 10,
    skip: DEFAULT_SKIP
  };

  return getSchoolSports(user, filter);
}

export function searchFunctionSportsWithFavorite(user: AppUser, text: string, isOnlyFavoriteSports: boolean) {
  const filter = {
    where: {
      name: {
        like: text || '',
        options: 'i'
      },
      isSchoolEventsAvailable: true
    },
    limit: 30,
    order: 'name ASC',
    skip: DEFAULT_SKIP
  };

  if (isOnlyFavoriteSports) {
    propz.set(filter, ['where', 'isFavorite'], isOnlyFavoriteSports);
  }

  return getSchoolSports(user, filter);
}

export function searchFunctionTeamSports(user: AppUser, text: string, isOnlyFavoriteSports: boolean) {
  const filter = {
    where: {
      name: {
        like: text || '',
        options: 'i'
      },
      isSchoolEventsAvailable: true,
      players: {
        $nin: [TYPE_OF_PLAYERS['1X1'], TYPE_OF_PLAYERS.INDIVIDUAL]
      }
    },
    limit: 10,
    skip: DEFAULT_SKIP
  };

  if (isOnlyFavoriteSports) {
    propz.set(filter, ['where', 'isFavorite'], isOnlyFavoriteSports);
  }

  return getSchoolSports(user, filter);
}

export function searchFunctionIndividualSports(user: AppUser, text: string, isOnlyFavoriteSports: boolean) {
  const filter = {
    where: {
      name: {
        like: text || '',
        options: 'i'
      },
      isSchoolEventsAvailable: true,
      players: {
        $nin: [TYPE_OF_PLAYERS['1X1'], TYPE_OF_PLAYERS['2X2'], TYPE_OF_PLAYERS.TEAM]
      }
    },
    limit: 10,
    skip: DEFAULT_SKIP
  };

  if (isOnlyFavoriteSports) {
    propz.set(filter, ['where', 'isFavorite'], isOnlyFavoriteSports);
  }

  return getSchoolSports(user, filter);
}

export function searchFunctionSportsNinSportIds(user: AppUser, text: string, sports: string[]) {
  const filter = {
    where: {
      _id: {
        $nin: sports
      },
      name: {
        like: text || '',
        options: 'i'
      },
      $or: [{ isSchoolEventsAvailable: true }, { isClubEventsAvailable: true }]
    },
    limit: 10,
    order: 'name ASC',
    skip: DEFAULT_SKIP
  };

  return getSchoolSports(user, filter);
}

export function searchFunctionClubSports(user: AppUser, text: string, where?) {
  const filter = {
    where: {
      name: {
        like: text || '',
        options: 'i'
      },
      isClubEventsAvailable: true,
      ...where
    },
    limit: 10,
    skip: DEFAULT_SKIP
  };

  return getSchoolClubSports(user, filter);
}

export function searchFunctionVenues(user: AppUser, text: string) {
  const filter = {
    where: {
      text: {
        like: text || '',
        options: 'i'
      }
    },
    limit: 10,
    skip: DEFAULT_SKIP
  };

  return getSchoolVenues(user, filter);
}

export function searchFunctionPlacesAndPostcodes(user: AppUser, text: string) {
  const region = user.activeSchool.region;
  const filter = {
    where: {
      text: {
        like: text || '',
        options: 'i'
      },
      region: region
    },
    limit: 10,
    skip: DEFAULT_SKIP
  };

  return getPlacesAndPostcodes(user, filter);
}

export function searchFunctionPaymentAccounts(user: AppUser) {
  const filter = {
    filter: {
      limit: DEFAULT_LIMIT,
      order: 'name ASC'
    }
  };

  return getPaymentAccounts(user, filter);
}

export function searchFunctionCoaches(
  user: AppUser,
  text: string,
  activeSchoolId: string,
  coaches: any[],
  sportId: string
) {
  const filter = {
    where: {
      _id: {
        $nin: coaches.map(coach => coach.id)
      },
      name: { like: text || '', options: 'i' },
      sportId: sportId
    },
    limit: 10,
    skip: DEFAULT_SKIP
  };

  return getCoaches(user, filter);
}

export function searchFunctionMembersOfStaff(
  user: AppUser,
  text: string,
  activeSchoolId: string,
  membersOfStaff: any[],
  sportId: string
) {
  const filter = {
    where: {
      _id: {
        $nin: membersOfStaff.map(member => member.id)
      },
      name: { like: text || '', options: 'i' },
      sportId: sportId
    },
    limit: 10,
    skip: DEFAULT_SKIP
  };

  return getStaffs(user, filter);
}

export function searchFunctionSchoolPlayers(user: AppUser, text: string, players: any[]) {
  const filter = {
    where: {
      _id: {
        $in: players.map(player => player.userId)
      }
    },
    limit: 10,
    skip: DEFAULT_SKIP
  };

  if (typeof text !== 'undefined') {
    filter.where['$or'] = [{ firstName: { like: text, options: 'i' } }, { lastName: { like: text, options: 'i' } }];
  }

  return getSchoolUsers(user, filter);
}

export function searchFunctionUsersByFirstName(user: AppUser, text: string) {
  const filter = {
    where: {
      firstName: {
        like: text,
        options: 'i'
      }
    },
    limit: 10,
    skip: DEFAULT_SKIP
  };

  return getSchoolUsers(user, filter);
}

export function searchFunctionUsersByLastName(user: AppUser, text: string) {
  const filter = {
    where: {
      lastName: {
        like: text,
        options: 'i'
      }
    },
    limit: 10,
    skip: DEFAULT_SKIP
  };

  return getSchoolUsers(user, filter);
}

export function searchFunctionStudentsByFirstName(user: AppUser, text: string) {
  const filter = {
    where: {
      firstName: {
        like: text,
        options: 'i'
      }
    },
    limit: 10,
    skip: DEFAULT_SKIP
  };

  return getSchoolStudents(user, filter);
}

export function searchFunctionStudentsByLastName(user: AppUser, text: string) {
  const filter = {
    where: {
      lastName: {
        like: text,
        options: 'i'
      }
    },
    limit: 10,
    skip: DEFAULT_SKIP
  };

  return getSchoolStudents(user, filter);
}

export function searchFunctionUsersByEmail(user: AppUser, text: string) {
  const filter = {
    where: {
      email: {
        like: text,
        options: 'i'
      }
    },
    limit: 10,
    skip: DEFAULT_SKIP
  };

  return getSchoolUsers(user, filter);
}

export function searchFunctionUserClubsByName(user: AppUser, text: string) {
  const filter = {
    where: {
      name: {
        like: text,
        options: 'i'
      }
    },
    limit: 10,
    skip: DEFAULT_SKIP
  };

  return getClubs(user, filter);
}

export function searchFunctionEventCoaches(
  user: AppUser,
  text: string,
  activeSchoolId: string,
  coaches: any[],
  eventId: string
) {
  const filter = {
    where: {
      _id: {
        $nin: coaches.map(coach => coach.id)
      },
      name: { like: text || '', options: 'i' }
    },
    limit: 10,
    skip: DEFAULT_SKIP
  };

  return getSchoolEventCoaches(user, eventId, filter);
}

export function searchFunctionEventMembersOfStaff(
  user: AppUser,
  text: string,
  activeSchoolId: string,
  membersOfStaff: any[],
  eventId: string
) {
  const filter = {
    where: {
      _id: {
        $nin: membersOfStaff.map(member => member.id)
      },
      name: { like: text || '', options: 'i' }
    },
    limit: 10,
    skip: DEFAULT_SKIP
  };

  return getSchoolEventStaff(user, eventId, filter);
}

export function getName(item): string {
  return item.name;
}

export function getSchoolName(item): string {
  return propz.get(item, ['school', 'name'], '');
}

export function getClubName(item): string {
  return propz.get(item, ['name'], '');
}

export function getFullName(item): string {
  if (typeof item === 'undefined') {
    return '';
  } else {
    return `${item.lastName} ${item.firstName}`;
  }
}

export function getFirstName(item): string {
  return typeof item === 'undefined' ? '' : item.firstName;
}

export function getLastName(item): string {
  return typeof item === 'undefined' ? '' : item.lastName;
}

export function getEmail(item): string {
  if (typeof item === 'undefined') {
    return '';
  } else {
    return item.email;
  }
}

export function getFirstNameAndFirstLetterOfLastName(item): string {
  if (typeof item === 'undefined') {
    return '';
  } else {
    const { firstName, lastName } = item;
    const lastNameInitial = lastName.split('')[0];

    return `${lastNameInitial}. ${firstName}`;
  }
}

export function getFullNameAndFormName(item): string {
  const firstName = propz.get(item, ['firstName']);
  const lastName = propz.get(item, ['lastName']);
  const formName = propz.get(item, ['form', 'name'], '');

  const name = `${firstName} ${lastName} ${formName}`;
  return name;
}

export function getPostcode(item): string {
  return item.postcode;
}

export function getNameAndAges(item): string {
  return `${item.name} ${item.ages.map(age => propz.get(AGE_GROUPS, ['ENGLISH', age], '')).join('; ')}`;
}

export function searchFunctionSchoolPostcodes(user: AppUser, text: string) {
  const region = propz.get(user, ['activeSchool', 'region']);

  const filter = {
    where: {
      postcode: {
        like: text || '',
        options: 'i'
      },
      region
    },
    limit: 10,
    skip: DEFAULT_SKIP
  };

  return getSchoolPostcode(filter);
}

export function searchFunctionHighLevelTournamentPlayers(
  text: string,
  user: AppUser,
  events: SchoolEvent[],
  forms: SchoolForm[],
  tournamentId: string,
  teamId: string,
  selectedStudentsIds: string[],
  isEventsForYongerGroup: boolean
) {
  const eventsIndividualPlayersIds = events.reduce((data, event) => {
    const { individualsData } = event;
    const individualPlayersIds = individualsData.map(item => item.userId);

    return [...data, ...individualPlayersIds];
  }, []);

  const eventsTeamPlayersIds = events.reduce((data, event) => {
    const { teamsData } = event;

    const teamPlayersIds = Lazy(teamsData)
      .map(item => item.players)
      .flatten()
      .toArray()
      .map(player => player.userId);

    return [...data, ...teamPlayersIds];
  }, []);

  const eventsPlayersIdsUniq = Lazy([...selectedStudentsIds, ...eventsIndividualPlayersIds, ...eventsTeamPlayersIds])
    .uniq()
    .toArray();

  const eventsDataAges = events.map(event => event.ages);

  const isEventsDataAgesEqual = eventsDataAges.reduce((isEqual, item, index) => {
    for (let i = 0; i < eventsDataAges.length; i++) {
      const element = eventsDataAges[i];
      if (i === index) continue;

      if (!isArraysEqual(element, item)) return false;
    }

    return true;
  }, true);

  const allEventsDataAges = events.reduce((result, event) => [...result, ...event.ages], []);

  let allEventsDataAgesUniq = Lazy(allEventsDataAges)
    .uniq()
    .sort()
    .toArray();

  if (isEventsForYongerGroup) {
    const minAge = Math.min.apply(null, allEventsDataAgesUniq);

    const youngerAges = [];

    for (let i = 0; i < minAge; i++) {
      youngerAges.push(i);
    }

    allEventsDataAgesUniq = isEventsDataAgesEqual
      ? [...youngerAges, ...allEventsDataAgesUniq]
      : [...youngerAges, allEventsDataAgesUniq[0]];
  }

  const formIds = Lazy(forms)
    .filter(form => allEventsDataAgesUniq.includes(form.age))
    .map(item => item.id)
    .uniq()
    .toArray();

  const eventsGenders = events
    .map(event => event.gender)
    .reduce((result, item) => {
      return Array.isArray(item) ? [...result, ...item] : [...result, item];
    }, []);

  const eventsGendersUniq = Lazy(eventsGenders)
    .uniq()
    .toArray();

  const isGenderSingle = eventsGendersUniq.length === 1;
  const singleGender = eventsGendersUniq[0];
  const isMixedGenderExist = eventsGendersUniq.includes(GENDER.MIXED);

  let genderArrayForSearch;
  let genderCondition = true;

  switch (true) {
    case isGenderSingle && isMixedGenderExist:
      genderArrayForSearch = [USER_GENDER.MALE, USER_GENDER.FEMALE];
      break;

    case isGenderSingle && !isMixedGenderExist:
      genderArrayForSearch = [SPORT_GENDER_TO_USER_GENDER_MAPPING[singleGender]];
      break;

    case eventsGendersUniq.length === 2 && isMixedGenderExist:
      const notMixedGender = eventsGendersUniq.find(gender => gender !== GENDER.MIXED);
      genderArrayForSearch = [SPORT_GENDER_TO_USER_GENDER_MAPPING[notMixedGender]];
      break;

    default:
      genderCondition = false;
      break;
  }

  const filter = {
    where: {
      formId: {
        $in: formIds
      },
      userId: {
        $nin: eventsPlayersIdsUniq
      },
      gender: {
        $in: genderArrayForSearch
      }
    },
    skip: DEFAULT_SKIP,
    limit: DEFAULT_LIMIT,
    order: 'startTime DESC'
  };

  if (typeof text !== 'undefined') {
    filter.where['name'] = { like: text };
  }

  const ageCondition = isEventsDataAgesEqual || isEventsForYongerGroup;

  return ageCondition && genderCondition ? getSchoolTournamentTeamPlayers(user, tournamentId, teamId, filter) : [];
}

export function searchFunctionHighLevelTournamentEvents(
  user: AppUser,
  students: IndividualTournamentTeamPlayer[],
  tournamentId: string,
  isEventsForOlderGroup: boolean,
  selectedEventsIds: string[],
  text: string
) {
  const studentsCount = students.length;
  const ageGroupsNaming = propz.get(user, ['activeSchool', 'ageGroupsNaming'], '');
  const ageGroupsCount = AGE_GROUPS[ageGroupsNaming].length;

  const studentEventIds = students.reduce((activeEvents, student) => {
    const { events } = student;
    const eventsIds = events.map(event => event.id);

    return [...activeEvents, ...eventsIds];
  }, []);

  const events = Lazy([...selectedEventsIds, ...studentEventIds])
    .uniq()
    .toArray();

  let formAges = [];

  switch (true) {
    case studentsCount === 1:
      const selectedStudent = students[0];
      const selectedStudentAge = propz.get(selectedStudent, ['form', 'age']);

      if (isEventsForOlderGroup) {
        for (let index = selectedStudentAge; index < ageGroupsCount; index++) {
          formAges.push(index);
        }
      } else {
        formAges.push(selectedStudentAge);
      }

      break;

    case studentsCount > 1:
      if (isEventsForOlderGroup) {
        const studentsAges = Lazy(students)
          .map(student => propz.get(student, ['form', 'age']))
          .uniq()
          .sort()
          .toArray();

        const maxAge = Math.max.apply(null, studentsAges);

        for (let index = maxAge + 1; index < ageGroupsCount; index++) {
          studentsAges.push(index);
        }

        formAges = studentsAges;
      } else {
        formAges = Lazy(students)
          .map(student => propz.get(student, ['form', 'age']))
          .uniq()
          .toArray();
      }
      break;
  }

  const studentsGenders: string[] = students.map(student => student.gender);
  let gender = [];

  switch (true) {
    case studentsGenders.includes(USER_GENDER.MALE) && studentsGenders.includes(USER_GENDER.FEMALE):
      gender = [GENDER.MIXED];
      break;

    case studentsGenders.includes(USER_GENDER.MALE):
      gender = [GENDER.MALE_ONLY, GENDER.MIXED];
      break;

    case studentsGenders.includes(USER_GENDER.FEMALE):
      gender = [GENDER.FEMALE_ONLY, GENDER.MIXED];
      break;
  }

  const filter = {
    where: {
      tournamentId,
      formAges,
      _id: {
        $nin: events
      },
      gender: {
        $in: gender
      }
    },

    limit: DEFAULT_LIMIT,
    order: 'startTime DESC'
  };

  return getHighLevelTournamentEvents(user, filter);
}

export function searchFunctionMergeableUsers(
  user: AppUser,
  text: string,
  requestedPermission,
  formId: string,
  houseId: string
) {
  let filter = {
    where: {},
    limit: 10,
    skip: DEFAULT_SKIP
  };

  if (typeof text !== 'undefined') {
    filter.where['$or'] = [{ firstName: { like: text, options: 'i' } }, { lastName: { like: text, options: 'i' } }];
  }
  switch (true) {
    case requestedPermission.preset === ROLE.STUDENT:
      if (typeof formId !== 'undefined' && formId !== '') {
        filter.where['permissions.details.formId'] = { $in: [formId] };
      }
      if (typeof houseId !== 'undefined' && houseId !== '') {
        filter.where['permissions.details.houseId'] = { $in: [houseId] };
      }
      filter.where['permissions.preset'] = { $in: ['STUDENT'] };

      filter.where['email'] = { $exists: false };

      return getSchoolUsers(user, filter);
    case requestedPermission.preset === ROLE.PARENT:
      filter.where['permissions.preset'] = { $in: ['PARENT'] };
      return getMergeableUsers(user, filter);
    default:
      filter.where['permissions.preset'] = { $in: ['ADMIN', 'MANAGER', 'TEACHER', 'COACH', 'GOVERNOR'] };
      return getMergeableUsers(user, filter);
  }
}

export function searchFunctionChampionships(user: AppUser, text: string) {
  const filter = {
    where: {
      name: {
        like: text || '',
        options: 'i'
      }
    },
    limit: 10,
    skip: DEFAULT_SKIP
  };

  return getChampionships(user, filter);
}

export function searchFunctionTournamentTeams(user, text, tournament, teams, ages, gender) {
  const { id } = tournament;

  const filter = {
    where: {
      isCreator: true,
      name: {
        like: text || '',
        options: 'i'
      }
    },
    limit: 10,
    order: 'name ASC'
  };

  const isMale = gender === GENDER.MALE_ONLY;
  const isFemale = gender === GENDER.FEMALE_ONLY;

  if (Array.isArray(teams) && teams.length > 0) {
    propz.set(
      filter,
      ['where', 'id', '$nin'],
      teams.map(team => team.id)
    );
  }

  if (isMale) {
    propz.set(filter, ['where', 'gender'], gender);
  }

  if (isFemale) {
    propz.set(filter, ['where', 'gender'], gender);
  }

  if (Array.isArray(ages) && ages.length > 0) {
    propz.set(filter, ['where', 'ages', '$in'], ages);
  }

  return getTournamentEventTeams(user, id, filter);
}

export function searchFunctionTournamentParticipants(user, text, tournament, participants) {
  const { id } = tournament;

  const filter = {
    where: {
      isCreator: true
    },
    limit: 250,
    skip: DEFAULT_SKIP,
    order: 'name ASC'
  };

  if (Array.isArray(participants) && participants.length > 0) {
    const schoolIds = participants.map(participant => participant.schoolId);
    propz.set(filter, ['where', 'id', '$nin'], schoolIds);
  }

  if (text) {
    const nameFilter = {
      like: text,
      options: 'i'
    };
    propz.set(filter, ['where', 'name'], nameFilter);
  }

  return getTournamentParticipants(user, id, filter);
}

export function searchFunctionHighLevelTournamentParticipants(user, text, tournament, participants) {
  const { id } = tournament;

  const filter = {
    where: {
      isParticipant: true
    },
    limit: DEFAULT_LIMIT,
    skip: DEFAULT_SKIP,
    order: 'name ASC'
  };

  if (Array.isArray(participants) && participants.length > 0) {
    const schoolIds = participants.map(participant => participant.schoolId);
    propz.set(filter, ['where', 'id', '$nin'], schoolIds);
  }

  if (text) {
    const nameFilter = {
      like: text,
      options: 'i'
    };
    propz.set(filter, ['where', 'name'], nameFilter);
  }

  return getTournamentParticipants(user, id, filter);
}

export function searchFunctionEventParticipants(text, user, participants, distance) {
  const activeSchoolId = propz.get(user, ['activeSchool', 'id'], '');
  const activeSchoolPostcode = propz.get(user, ['activeSchool', 'postcode']);
  const point = propz.get(activeSchoolPostcode, ['point']);
  const region = propz.get(user, ['activeSchool', 'region']);
  const filter = {
    where: {
      id: {
        $nin: [activeSchoolId]
      }
    },
    limit: 10,
    skip: DEFAULT_SKIP
  };

  if (Array.isArray(participants) && participants.length > 0) {
    const participantIds = participants.map(participant => participant.id);
    propz.set(filter, ['where', 'id', '$nin'], [...participantIds, activeSchoolId]);
  }

  if (text) {
    const nameFilter = {
      like: text,
      options: 'i'
    };
    propz.set(filter, ['where', 'name'], nameFilter);
  }

  if (typeof activeSchoolPostcode !== 'undefined') {
    propz.set(filter, ['where', 'postcode.point'], getMainGeoSchoolFilterByParams(distance, point));
  } else {
    propz.set(filter, ['where', 'order'], 'name ASC');
  }

  if (typeof region !== 'undefined') {
    propz.set(filter, ['where', 'region'], region);
  }

  let schools;

  return getSchoolsPublic(filter)
    .then(_schools => {
      schools = _schools;

      const filterTbd = {
        where: {
          name: { like: 'TBD' }
        },
        limit: 10,
        skip: DEFAULT_SKIP
      };

      if (Array.isArray(participants) && participants.length > 0) {
        const participantIds = participants.map(participant => participant.id);
        propz.set(filterTbd, ['where', 'id', '$nin'], [...participantIds, activeSchoolId]);
      }

      return getSchoolsPublic(filterTbd);
    })
    .then(data => {
      if (data.length > 0 && data[0].name === 'TBD') {
        // set TBD school at first
        schools.unshift(data[0]);
        const schoolsUniq = Lazy(schools)
          .uniq('id')
          .toArray();
        return schoolsUniq;
      } else {
        return schools;
      }
    });
}

export function searchFunctionEventAddOrChangeSchool(
  text: string,
  user: AppUser,
  event: SchoolEvent,
  distance: number
) {
  const { inviterSchoolId, invitedSchoolIds } = event;
  const allSchoolIds = [...invitedSchoolIds, inviterSchoolId];
  const activeSchoolPostcode = propz.get(user, ['activeSchool', 'postcode']);
  const point = propz.get(activeSchoolPostcode, ['point']);
  const region = propz.get(user, ['activeSchool', 'region']);
  const filter = {
    where: {
      id: {
        $nin: allSchoolIds
      }
    },
    limit: 10,
    skip: DEFAULT_SKIP
  };

  if (text) {
    const nameFilter = {
      like: text,
      options: 'i'
    };
    propz.set(filter, ['where', 'name'], nameFilter);
  }

  if (typeof activeSchoolPostcode !== 'undefined') {
    propz.set(filter, ['where', 'postcode.point'], getMainGeoSchoolFilterByParams(distance, point));
  } else {
    propz.set(filter, ['where', 'order'], 'name ASC');
  }

  if (typeof region !== 'undefined') {
    propz.set(filter, ['where', 'region'], region);
  }

  return getSchoolsPublic(filter);
}

export function searchFunctionParticipantsOfTournament(
  text: string,
  user: AppUser,
  event: SchoolEvent,
  participants: Participant[]
) {
  const tournamentId = propz.get(event, ['tournament', 'id'], '');

  const participantsIds = participants.map(participant => {
    const participantId = propz.get(participant, ['school', 'id'], '');
    return participantId;
  });

  const filter = {
    where: { id: { $nin: participantsIds } },
    limit: 50,
    skip: DEFAULT_SKIP
  };

  if (text) {
    const nameFilter = {
      like: text,
      options: 'i'
    };
    propz.set(filter, ['where', 'name'], nameFilter);
  }

  return getTournamentParticipants(user, tournamentId, filter);
}

export function getNameByPlaceOrPostcode(item): string {
  let tooltip;

  switch (true) {
    //places and postcodes venue
    case typeof item.name !== 'undefined':
      const postcode = propz.get(item, ['postcode'], '');
      return `${item.name} (${postcode})`;
    case typeof item.postcode !== 'undefined':
      tooltip = typeof item.tooltip !== 'undefined' ? item.tooltip : '';
      return `${item.postcode} ${tooltip}`;
    //tournament venue
    case typeof item.placeData !== 'undefined':
      return propz.get(item, ['placeData', 'name'], '');
    case typeof item.postcodeData !== 'undefined':
      tooltip = propz.get(item, ['postcodeData', 'tooltip'], '');
      const activeSchoolPostcode = propz.get(item, ['postcodeData', 'postcode'], '');
      return `${activeSchoolPostcode} ${tooltip}`;
    case item.venueType === EVENT_VENUE.TBD:
      return EVENT_VENUE.TBD;
    case typeof item.postcodeNoSpaces !== 'undefined':
      return `${item.postcodeNoSpaces}`;
    default:
      console.error(`Can not get name: ${JSON.stringify(item, null, 2)}`);
      return '';
  }
}

export function getSchoolNameAndTeamName(item): string {
  const schoolName = propz.get(item, ['school', 'name'], '');
  const teamName = propz.get(item, ['name'], '');
  return `${schoolName} (${teamName})`;
}

export function searchFunctionSchools(user, text, postcode) {
  const point = propz.get(postcode, ['point']);
  const phone = propz.get(user, ['profile', 'phone']);
  const region = getPhoneRegion(phone);

  const filter = {
    where: {
      name: {
        like: text || '',
        options: 'i'
      },
      region,
      kind: { $in: ['School', 'SchoolUnion'] },
      availableForRegistration: {
        $ne: false
      },
      $or: [
        {
          'allowedPermissionPresets.ADMIN': { $ne: false }
        },
        {
          'allowedPermissionPresets.MANAGER': {
            $ne: false
          }
        },
        {
          'allowedPermissionPresets.TEACHER': {
            $ne: false
          }
        },
        {
          'allowedPermissionPresets.COACH': {
            $ne: false
          }
        },
        {
          'allowedPermissionPresets.STUDENT': {
            $ne: false
          }
        },
        {
          'allowedPermissionPresets.PARENT': {
            $ne: false
          }
        },
        {
          'allowedPermissionPresets.TOURNAMENT_FOlLOWER': {
            $ne: false
          }
        }
      ]
    },
    limit: 20,
    skip: DEFAULT_SKIP
  };

  if (typeof postcode !== 'undefined') {
    propz.set(filter, ['where', 'postcode.point'], getMainGeoSchoolFilterByParams(0, point));
  } else {
    propz.set(filter, ['order'], 'name ASC');
  }

  return getSchoolsPublic(filter);
}

export function searchFunctionSchoolFormsPublic(text: string, schoolId: string) {
  const filter = {
    where: {
      name: {
        like: text || '',
        options: 'i'
      }
    },
    limit: 10,
    skip: DEFAULT_SKIP
  };

  return getSchoolFormsPublic(schoolId, filter);
}

export function searchFunctionSchoolHousesPublic(text: string, schoolId: string) {
  const filter = {
    where: {
      name: {
        like: text || '',
        options: 'i'
      }
    },
    limit: 10,
    skip: DEFAULT_SKIP
  };

  return getSchoolHousesPublic(schoolId, filter);
}

export function searchFunctionSchoolSports(text: string, schoolId: string, sportIds: string[]) {
  const filter = {
    where: {
      $or: [{ isSchoolEventsAvailable: true }, { isClubEventsAvailable: true }],
      name: {
        like: text || '',
        options: 'i'
      }
    },
    limit: 100,
    skip: DEFAULT_SKIP
  };

  if (sportIds.length > 0) {
    propz.set(filter, ['where', 'id'], { $nin: sportIds });
  }

  return getSchoolSportsPublic(schoolId, filter);
}

export function searchFunctionSchoolTournaments(text: string, schoolId: string) {
  const filter = {
    where: {
      name: {
        like: text || '',
        options: 'i'
      }
    },
    limit: 10,
    skip: DEFAULT_SKIP
  };

  return getSchoolTournamentsPublic(schoolId, filter);
}

export function searchFunctionSchoolTeams(
  user: AppUser,
  options: {
    text: string;
    teamIds: string[];
    gender: GENDER_TYPE;
    ages: number[];
    sportId: string;
    houses: any[];
  }
): Promise<Team[]> {
  const { text, teamIds, gender, ages, sportId, houses = [] } = options;

  const filter = {
    where: {
      name: {
        like: text || '',
        options: 'i'
      },
      gender: gender,
      sportId: sportId,
      id: {
        $nin: teamIds
      }
    },
    limit: 10,
    skip: DEFAULT_SKIP
  };

  if (ages.length > 0) {
    propz.set(filter, ['where', 'ages'], { $in: ages });
  }

  if (houses.length > 0) {
    propz.set(
      filter,
      ['where', 'houseId', '$in'],
      houses.map(house => house.id)
    );
  }

  return getSchoolTeams(user, filter);
}

export function searchFunctionSchoolNinSchools(text: string, schools: School[], postcode) {
  const point = propz.get(postcode, ['point']);
  const filter = {
    where: {
      id: {
        $nin: schools.map(school => school.id)
      },
      name: { like: text || '', options: 'i' }
    },
    limit: 10,
    skip: DEFAULT_SKIP
  };

  if (typeof postcode !== 'undefined') {
    propz.set(filter, ['where', 'postcode.point'], getMainGeoSchoolFilterByParams(0, point));
  } else {
    propz.set(filter, ['order'], 'name ASC');
  }

  return getSchoolsPublic(filter);
}
