export const TOURNAMENT_SEASONS_MAX = 2024;
export const TOURNAMENT_SEASONS_MIN = 1986;

export const TOURNAMENT_TABS = [
  'Summary',
  'Participants',
  'Eligible schools',
  'Confirmation Requests',
  'Tournament Events',
  'Auto enrollment',
  'Public site settings',
  'Enrollment log',
  'Admins'
];

export const TEAM_TOURNAMENT_TABS = [
  'Summary',
  'Participants',
  'Eligible schools',
  'Confirmation Requests',
  'Tournament Events',
  'Auto enrollment',
  'Public site settings',
  'Tournament TV',
  'Enrollment log',
  'Admins',
  'Pitches',
  'Groups',
  'Teams'
];

export const TOURNAMENT_CONFIRMATION_REQUEST_TABS = ['Answers'];

export const TOURNAMENT_TYPE = {
  TEAM_TOURNAMENT: 'TEAM_TOURNAMENT',
  INDIVIDUAL_TOURNAMENT: 'INDIVIDUAL_TOURNAMENT',
  SINGLE_DOUBLE_TOURNAMENT: 'SINGLE_DOUBLE_TOURNAMENT',
  MINI_TEAM_TOURNAMENT: 'MINI_TEAM_TOURNAMENT'
};

export const TOURNAMENT_TYPE_SERVER_TO_CLIENT_MAPPING = {
  TEAM_TOURNAMENT: 'Team tournament',
  INDIVIDUAL_TOURNAMENT: 'Individual tournament',
  SINGLE_DOUBLE_TOURNAMENT: 'Single-Double tournament',
  MINI_TEAM_TOURNAMENT: 'Mini team tournament'
};

export const TOURNAMENT_SUBTYPE = {
  ATHLETIC_SWIMMING: 'ATHLETIC_SWIMMING',
  REGULAR_INDIVIDUAL: 'REGULAR_INDIVIDUAL',
  TRIATHLON: 'TRIATHLON',
  NATIONAL_TRIATHLON: 'NATIONAL_TRIATHLON',
  CROSS_COUNTRY: 'CROSS_COUNTRY',
  NATIONAL_CROSS_COUNTRY: 'NATIONAL_CROSS_COUNTRY',
  FOOTBALL: 'FOOTBALL',
  CRICKET: 'CRICKET',
  SAILING: 'SAILING',
  RUGBY: 'RUGBY',
  NETBALL: 'NETBALL',
  WINNERS_ONLY: 'WINNERS_ONLY',
  BADMINTON: 'BADMINTON',
  BADMINTON_MT: 'BADMINTON_MT',
  FENCING: 'FENCING',
  TENNIS: 'TENNIS',
  PSEUDO_TWO_LEVEL: 'PSEUDO_TWO_LEVEL',
  PSEUDO_TWO_LEVEL_RUNTIME: 'PSEUDO_TWO_LEVEL_RUNTIME'
};

export const TOURNAMENT_SUBTYPE_SERVER_TO_CLIENT_MAPPING = {
  ATHLETIC_SWIMMING: 'Athletics/Swimming',
  REGULAR_INDIVIDUAL: 'Athletic/Swimming (aggregated age groups)',
  TRIATHLON: 'Junior triathlon',
  NATIONAL_TRIATHLON: 'National triathlon',
  CROSS_COUNTRY: 'Cross-country',
  NATIONAL_CROSS_COUNTRY: 'Cross-country (aggregated age groups)',
  FOOTBALL: 'Football',
  CRICKET: 'Cricket',
  SAILING: 'Sailing',
  RUGBY: 'Rugby',
  NETBALL: 'Netball',
  WINNERS_ONLY: 'Winners only',
  BADMINTON: 'Badminton',
  BADMINTON_MT: 'Badminton mini team',
  FENCING: 'Fencing',
  TENNIS: 'Tennis',
  PSEUDO_TWO_LEVEL: 'Pseudo 2-level',
  PSEUDO_TWO_LEVEL_RUNTIME: 'Pseudo 2-level runtime'
};

export const PUBLIC_SITE_STATUS_OPTIONS_SERVER_TO_CLIENT_MAPPING = {
  DISABLED: 'DISABLED',
  PUBLIC_AVAILABLE: 'PUBLIC_AVAILABLE',
  PROTECTED: 'PROTECTED'
};

export const PUBLIC_SITE_STATUS_OPTIONS = {
  DISABLED: 'Disabled',
  PUBLIC_AVAILABLE: 'Enabled',
  PROTECTED: 'Protected'
};

export const TOURNAMENT_PRICE_TYPE = {
  FREE: 'FREE',
  PAID: 'PAID'
};

export const TOURNAMENT_PAYMENT_TYPE = {
  FIXED_PRICE: 'FIXED_PRICE'
};

export const DEFAULT_EVENT_IMPORT_PROPERTIES = {
  playerNumber: 'race_no',
  teamCode: 'team_code',
  result: 'result',
  delimiter: ','
};

export const TOURNAMENT_LAYOUT_TYPE = {
  GROUP: 'GROUP',
  QUALIFIER: 'QUALIFIER',
  PLAYOFF: 'PLAYOFF',
  FINAL: 'FINAL'
};

export const TOURNAMENT_GROUP_TAG_MAPPING = {
  //group
  GROUP_A: 'Group A',
  GROUP_B: 'Group B',
  GROUP_C: 'Group C',
  GROUP_D: 'Group D',
  GROUP_E: 'Group E',
  GROUP_F: 'Group F',
  GROUP_G: 'Group G',
  GROUP_H: 'Group H',

  GROUP_A_BOYS: 'Group A Boys',
  GROUP_B_BOYS: 'Group B Boys',
  GROUP_C_BOYS: 'Group C Boys',
  GROUP_D_BOYS: 'Group D Boys',

  GROUP_A_GIRLS: 'Group A Girls',
  GROUP_B_GIRLS: 'Group B Girls',
  GROUP_C_GIRLS: 'Group C Girls',
  GROUP_D_GIRLS: 'Group D Girls',

  GROUP_A_BOYS_DOUBLES: 'Group A Boys Doubles',
  GROUP_B_BOYS_DOUBLES: 'Group B Boys Doubles',

  GROUP_A_GIRLS_DOUBLES: 'Group A Girls Doubles',
  GROUP_B_GIRLS_DOUBLES: 'Group B Girls Doubles',

  FIRST_ROUND_POOL_A: 'First Round Pool A',
  FIRST_ROUND_POOL_B: 'First Round Pool B',

  DEEP_WATER: 'Deep water',
  SHALLOW_WATER: 'Shallow water',

  // hack for sailing
  SLOW_HANDICAP: 'Slow Handicap',
  FAST_HANDICAP: 'Fast Handicap',
  ROOKIE_FLEET: 'Rookie Fleet',

  //qualifier
  CUP_A: 'Cup A',
  CUP_B: 'Cup B',
  PLATE_A: 'Plate A',
  PLATE_B: 'Plate B',
  BOWL_A: 'Bowl A',
  BOWL_B: 'Bowl B',
  TROPHY_A: 'Trophy A',
  TROPHY_B: 'Trophy B',

  //playoff
  PLAYOFF: 'Playoff',
  SECOND_PLAYOFF: '2nd Playoff',
  THIRD_PLAYOFF: '3d Playoff',
  FOURTH_PLAYOFF: '4th Playoff',

  CUP_PLAYOFF_DOUBLES: 'Cup Playoff Doubles',
  CUP_PLAYOFF: 'Cup playoff',
  PLATE_PLAYOFF: 'Plate playoff',
  BOWL_PLAYOFF: 'Bowl playoff',
  TROPHY_PLAYOFF: 'Trophy playoff',
  SHIELD_PLAYOFF: 'Shield playoff',

  //rugby playoff
  SECOND_ROUND: 'Second Round',

  //finals
  CUP_SEMIFINAL: 'Cup semifinal',
  CUP_FINAL: 'Cup final',
  PLATE_SEMIFINAL: 'Plate semifinal',
  PLATE_FINAL: 'Plate final',
  BOWL_SEMIFINAL: 'Bowl semifinal',
  BOWL_FINAL: 'Bowl final',
  TROPHY_SEMIFINAL: 'Trophy semifinal',
  TROPHY_FINAL: 'Trophy final',
  SHIELD_SEMIFINAL: 'Shield semifinal',
  SHIELD_FINAL: 'Shield final',

  CUP_SEMIFINAL_DOUBLES: 'Cup Semifinal Doubles',
  CUP_FINAL_DOUBLES: 'Cup Final Doubles'
};

export const TOURNAMENT_GROUP_TAG_MAPPING_REVERSE = {
  //group
  'Group A': 'GROUP_A',
  'Group B': 'GROUP_B',
  'Group C': 'GROUP_C',
  'Group D': 'GROUP_D',
  'Group E': 'GROUP_E',
  'Group F': 'GROUP_F',
  'Group G': 'GROUP_G',
  'Group H': 'GROUP_H',

  'Group A Boys': 'GROUP_A_BOYS',
  'Group B Boys': 'GROUP_B_BOYS',
  'Group C Boys': 'GROUP_C_BOYS',
  'Group D Boys': 'GROUP_D_BOYS',

  'Group A Girls': 'GROUP_A_GIRLS',
  'Group B Girls': 'GROUP_B_GIRLS',
  'Group C Girls': 'GROUP_C_GIRLS',
  'Group D Girls': 'GROUP_D_GIRLS',

  'Group A Boys Doubles': 'GROUP_A_BOYS_DOUBLES',
  'Group B Boys Doubles': 'GROUP_B_BOYS_DOUBLES',

  'Group A Girls Doubles': 'GROUP_A_GIRLS_DOUBLES',
  'Group B Girls Doubles': 'GROUP_B_GIRLS_DOUBLES',

  'First Round Pool A': 'FIRST_ROUND_POOL_A',
  'First Round Pool B': 'FIRST_ROUND_POOL_B',

  'Deep water': 'DEEP_WATER',
  'Shallow water': 'SHALLOW_WATER',

  // hack for sailing
  'Slow Handicap': 'SLOW_HANDICAP',
  'Fast Handicap': 'FAST_HANDICAP',
  'Rookie Fleet': 'ROOKIE_FLEET',

  //qualifier
  'Cup A': 'CUP_A',
  'Cup B': 'CUP_B',
  'Plate A': 'PLATE_A',
  'Plate B': 'PLATE_B',
  'Bowl A': 'BOWL_A',
  'Bowl B': 'BOWL_B',
  'Trophy A': 'TROPHY_A',
  'Trophy B': 'TROPHY_B',

  //playoff
  Playoff: 'PLAYOFF',
  '2nd Playoff': 'SECOND_PLAYOFF',
  '3d Playoff': 'THIRD_PLAYOFF',
  '4th Playoff': 'FOURTH_PLAYOFF',

  'Cup Playoff Doubles': 'CUP_PLAYOFF_DOUBLES',
  'Cup playoff': 'CUP_PLAYOFF',
  'Plate playoff': 'PLATE_PLAYOFF',
  'Bowl playoff': 'BOWL_PLAYOFF',
  'Trophy playoff': 'TROPHY_PLAYOFF',
  'Shield playoff': 'SHIELD_PLAYOFF',

  //rugby playoff
  'Second Round': 'SECOND_ROUND',

  //finals
  'Cup semifinal': 'CUP_SEMIFINAL',
  'Cup final': 'CUP_FINAL',
  'Plate semifinal': 'PLATE_SEMIFINAL',
  'Plate final': 'PLATE_FINAL',
  'Bowl semifinal': 'BOWL_SEMIFINAL',
  'Bowl final': 'BOWL_FINAL',
  'Trophy semifinal': 'TROPHY_SEMIFINAL',
  'Trophy final': 'TROPHY_FINAL',
  'Shield semifinal': 'SHIELD_SEMIFINAL',
  'Shield final': 'SHIELD_FINAL',

  'Cup Semifinal Doubles': 'CUP_SEMIFINAL_DOUBLES',
  'Cup Final Doubles': 'CUP_FINAL_DOUBLES'
};

export const TOURNAMENT_GROUP_TAG_FOR_FORM = {
  GROUP_A: 'GROUP_A',
  GROUP_B: 'GROUP_B',
  GROUP_C: 'GROUP_C',
  GROUP_D: 'GROUP_D',
  GROUP_E: 'GROUP_E',
  GROUP_F: 'GROUP_F',
  GROUP_G: 'GROUP_G',
  GROUP_H: 'GROUP_H',

  GROUP_A_BOYS: 'GROUP_A_BOYS',
  GROUP_B_BOYS: 'GROUP_B_BOYS',
  GROUP_C_BOYS: 'GROUP_C_BOYS',
  GROUP_D_BOYS: 'GROUP_D_BOYS',

  GROUP_A_GIRLS: 'GROUP_A_GIRLS',
  GROUP_B_GIRLS: 'GROUP_B_GIRLS',
  GROUP_C_GIRLS: 'GROUP_C_GIRLS',
  GROUP_D_GIRLS: 'GROUP_D_GIRLS',

  GROUP_A_BOYS_DOUBLES: 'GROUP_A_BOYS_DOUBLES',
  GROUP_B_BOYS_DOUBLES: 'GROUP_B_BOYS_DOUBLES',

  GROUP_A_GIRLS_DOUBLES: 'GROUP_A_GIRLS_DOUBLES',
  GROUP_B_GIRLS_DOUBLES: 'GROUP_B_GIRLS_DOUBLES',

  FIRST_ROUND_POOL_A: 'FIRST_ROUND_POOL_A',
  FIRST_ROUND_POOL_B: 'FIRST_ROUND_POOL_B',

  DEEP_WATER: 'DEEP_WATER',
  SHALLOW_WATER: 'SHALLOW_WATER',

  // oh. This is hack for sailing because everything is fucked up!
  SLOW_HANDICAP: 'SLOW_HANDICAP',
  FAST_HANDICAP: 'FAST_HANDICAP',
  ROOKIE_FLEET: 'ROOKIE_FLEET',

  CUP_A: 'CUP_A',
  CUP_B: 'CUP_B',
  PLATE_A: 'PLATE_A',
  PLATE_B: 'PLATE_B',
  BOWL_A: 'BOWL_A',
  BOWL_B: 'BOWL_B',
  TROPHY_A: 'TROPHY_A',
  TROPHY_B: 'TROPHY_B',

  CUP_PLAYOFF_DOUBLES: 'CUP_PLAYOFF_DOUBLES',
  CUP_PLAYOFF: 'CUP_PLAYOFF',
  PLATE_PLAYOFF: 'PLATE_PLAYOFF',
  BOWL_PLAYOFF: 'BOWL_PLAYOFF',
  TROPHY_PLAYOFF: 'TROPHY_PLAYOFF',
  SHIELD_PLAYOFF: 'SHIELD_PLAYOFF',

  PLAYOFF: 'PLAYOFF',
  SECOND_PLAYOFF: 'SECOND_PLAYOFF',
  THIRD_PLAYOFF: 'THIRD_PLAYOFF',
  FOURTH_PLAYOFF: 'FOURTH_PLAYOFF',

  SECOND_ROUND: 'SECOND_ROUND',

  CUP_SEMIFINAL: 'CUP_SEMIFINAL',
  CUP_FINAL: 'CUP_FINAL',
  PLATE_SEMIFINAL: 'PLATE_SEMIFINAL',
  PLATE_FINAL: 'PLATE_FINAL',
  BOWL_SEMIFINAL: 'BOWL_SEMIFINAL',
  BOWL_FINAL: 'BOWL_FINAL',
  TROPHY_SEMIFINAL: 'TROPHY_SEMIFINAL',
  TROPHY_FINAL: 'TROPHY_FINAL',
  SHIELD_SEMIFINAL: 'SHIELD_SEMIFINAL',
  SHIELD_FINAL: 'SHIELD_FINAL',

  CUP_SEMIFINAL_DOUBLES: 'CUP_SEMIFINAL_DOUBLES',
  CUP_FINAL_DOUBLES: 'CUP_FINAL_DOUBLES'
};

export const FOOTBALL_GROUP_STAGES = [
  TOURNAMENT_GROUP_TAG_FOR_FORM.GROUP_A,
  TOURNAMENT_GROUP_TAG_FOR_FORM.GROUP_B,
  TOURNAMENT_GROUP_TAG_FOR_FORM.GROUP_C,
  TOURNAMENT_GROUP_TAG_FOR_FORM.GROUP_D,
  TOURNAMENT_GROUP_TAG_FOR_FORM.GROUP_E,
  TOURNAMENT_GROUP_TAG_FOR_FORM.GROUP_F,
  TOURNAMENT_GROUP_TAG_FOR_FORM.GROUP_G,
  TOURNAMENT_GROUP_TAG_FOR_FORM.GROUP_H
];

export const FOOTBALL_QUALIFIERS_STAGES = [
  TOURNAMENT_GROUP_TAG_FOR_FORM.CUP_A,
  TOURNAMENT_GROUP_TAG_FOR_FORM.CUP_B,
  TOURNAMENT_GROUP_TAG_FOR_FORM.PLATE_A,
  TOURNAMENT_GROUP_TAG_FOR_FORM.PLATE_B,
  TOURNAMENT_GROUP_TAG_FOR_FORM.BOWL_A,
  TOURNAMENT_GROUP_TAG_FOR_FORM.BOWL_B,
  TOURNAMENT_GROUP_TAG_FOR_FORM.TROPHY_A,
  TOURNAMENT_GROUP_TAG_FOR_FORM.TROPHY_B
];

export const FOOTBALL_PLAYOFF_STAGES = [
  TOURNAMENT_GROUP_TAG_FOR_FORM.PLAYOFF,
  TOURNAMENT_GROUP_TAG_FOR_FORM.SECOND_PLAYOFF,
  TOURNAMENT_GROUP_TAG_FOR_FORM.THIRD_PLAYOFF,
  TOURNAMENT_GROUP_TAG_FOR_FORM.FOURTH_PLAYOFF
];

export const FOOTBALL_THIRD_PLACE_STAGES = [
  TOURNAMENT_GROUP_TAG_FOR_FORM.CUP_PLAYOFF,
  TOURNAMENT_GROUP_TAG_FOR_FORM.PLATE_PLAYOFF,
  TOURNAMENT_GROUP_TAG_FOR_FORM.BOWL_PLAYOFF,
  TOURNAMENT_GROUP_TAG_FOR_FORM.TROPHY_PLAYOFF,
  TOURNAMENT_GROUP_TAG_FOR_FORM.SHIELD_PLAYOFF
];

export const FOOTBALL_FINALS_STAGES = [
  TOURNAMENT_GROUP_TAG_FOR_FORM.CUP_SEMIFINAL,
  TOURNAMENT_GROUP_TAG_FOR_FORM.CUP_FINAL,
  TOURNAMENT_GROUP_TAG_FOR_FORM.PLATE_SEMIFINAL,
  TOURNAMENT_GROUP_TAG_FOR_FORM.PLATE_FINAL,
  TOURNAMENT_GROUP_TAG_FOR_FORM.BOWL_SEMIFINAL,
  TOURNAMENT_GROUP_TAG_FOR_FORM.BOWL_FINAL,
  TOURNAMENT_GROUP_TAG_FOR_FORM.TROPHY_SEMIFINAL,
  TOURNAMENT_GROUP_TAG_FOR_FORM.TROPHY_FINAL,
  TOURNAMENT_GROUP_TAG_FOR_FORM.SHIELD_SEMIFINAL,
  TOURNAMENT_GROUP_TAG_FOR_FORM.SHIELD_FINAL
];

export const BADMINTON_GROUP_STAGES = [
  TOURNAMENT_GROUP_TAG_FOR_FORM.GROUP_A_BOYS,
  TOURNAMENT_GROUP_TAG_FOR_FORM.GROUP_B_BOYS,
  TOURNAMENT_GROUP_TAG_FOR_FORM.GROUP_C_BOYS,
  TOURNAMENT_GROUP_TAG_FOR_FORM.GROUP_D_BOYS,
  TOURNAMENT_GROUP_TAG_FOR_FORM.GROUP_A_GIRLS,
  TOURNAMENT_GROUP_TAG_FOR_FORM.GROUP_B_GIRLS,
  TOURNAMENT_GROUP_TAG_FOR_FORM.GROUP_C_GIRLS,
  TOURNAMENT_GROUP_TAG_FOR_FORM.GROUP_D_GIRLS,
  TOURNAMENT_GROUP_TAG_FOR_FORM.GROUP_A_BOYS_DOUBLES,
  TOURNAMENT_GROUP_TAG_FOR_FORM.GROUP_B_BOYS_DOUBLES,
  TOURNAMENT_GROUP_TAG_FOR_FORM.GROUP_A_GIRLS_DOUBLES,
  TOURNAMENT_GROUP_TAG_FOR_FORM.GROUP_B_GIRLS_DOUBLES
];

export const BADMINTON_QUALIFIERS_STAGES = [];

export const BADMINTON_PLAYOFF_STAGES = [];

export const BADMINTON_THIRD_PLACE_STAGES = [TOURNAMENT_GROUP_TAG_FOR_FORM.CUP_PLAYOFF_DOUBLES];

export const BADMINTON_FINALS_STAGES = [
  TOURNAMENT_GROUP_TAG_FOR_FORM.CUP_SEMIFINAL_DOUBLES,
  TOURNAMENT_GROUP_TAG_FOR_FORM.CUP_FINAL_DOUBLES
];

export const RUGBY_GROUP_STAGES = [
  TOURNAMENT_GROUP_TAG_FOR_FORM.FIRST_ROUND_POOL_A,
  TOURNAMENT_GROUP_TAG_FOR_FORM.FIRST_ROUND_POOL_B
];
export const RUGBY_PLAYOFF_STAGES = [TOURNAMENT_GROUP_TAG_FOR_FORM.SECOND_ROUND];

export const SAILING_GROUP_STAGES = [
  TOURNAMENT_GROUP_TAG_FOR_FORM.FAST_HANDICAP,
  TOURNAMENT_GROUP_TAG_FOR_FORM.SLOW_HANDICAP,
  TOURNAMENT_GROUP_TAG_FOR_FORM.ROOKIE_FLEET
];

export const SWIMMING_GROUP_STAGES = [
  TOURNAMENT_GROUP_TAG_FOR_FORM.DEEP_WATER,
  TOURNAMENT_GROUP_TAG_FOR_FORM.SHALLOW_WATER
];

export const TOURNAMENT_GROUP_STAGE = [
  TOURNAMENT_GROUP_TAG_MAPPING.GROUP_A,
  TOURNAMENT_GROUP_TAG_MAPPING.GROUP_B,
  TOURNAMENT_GROUP_TAG_MAPPING.GROUP_C,
  TOURNAMENT_GROUP_TAG_MAPPING.GROUP_D,
  TOURNAMENT_GROUP_TAG_MAPPING.GROUP_E,
  TOURNAMENT_GROUP_TAG_MAPPING.GROUP_F,
  TOURNAMENT_GROUP_TAG_MAPPING.GROUP_G,
  TOURNAMENT_GROUP_TAG_MAPPING.GROUP_H,

  TOURNAMENT_GROUP_TAG_MAPPING.GROUP_A_BOYS,
  TOURNAMENT_GROUP_TAG_MAPPING.GROUP_B_BOYS,
  TOURNAMENT_GROUP_TAG_MAPPING.GROUP_C_BOYS,
  TOURNAMENT_GROUP_TAG_MAPPING.GROUP_D_BOYS,

  TOURNAMENT_GROUP_TAG_MAPPING.GROUP_A_GIRLS,
  TOURNAMENT_GROUP_TAG_MAPPING.GROUP_B_GIRLS,
  TOURNAMENT_GROUP_TAG_MAPPING.GROUP_C_GIRLS,
  TOURNAMENT_GROUP_TAG_MAPPING.GROUP_D_GIRLS,

  TOURNAMENT_GROUP_TAG_MAPPING.GROUP_A_BOYS_DOUBLES,
  TOURNAMENT_GROUP_TAG_MAPPING.GROUP_B_BOYS_DOUBLES,

  TOURNAMENT_GROUP_TAG_MAPPING.GROUP_A_GIRLS_DOUBLES,
  TOURNAMENT_GROUP_TAG_MAPPING.GROUP_B_GIRLS_DOUBLES,

  TOURNAMENT_GROUP_TAG_MAPPING.FIRST_ROUND_POOL_A,
  TOURNAMENT_GROUP_TAG_MAPPING.FIRST_ROUND_POOL_B,
  TOURNAMENT_GROUP_TAG_MAPPING.DEEP_WATER,
  TOURNAMENT_GROUP_TAG_MAPPING.SHALLOW_WATER,
  TOURNAMENT_GROUP_TAG_MAPPING.SLOW_HANDICAP,
  TOURNAMENT_GROUP_TAG_MAPPING.FAST_HANDICAP,
  TOURNAMENT_GROUP_TAG_MAPPING.ROOKIE_FLEET
];

export const TOURNAMENT_QUALIFIER_STAGE = [
  TOURNAMENT_GROUP_TAG_MAPPING.CUP_A,
  TOURNAMENT_GROUP_TAG_MAPPING.CUP_B,
  TOURNAMENT_GROUP_TAG_MAPPING.PLATE_A,
  TOURNAMENT_GROUP_TAG_MAPPING.PLATE_B,
  TOURNAMENT_GROUP_TAG_MAPPING.BOWL_A,
  TOURNAMENT_GROUP_TAG_MAPPING.BOWL_B,
  TOURNAMENT_GROUP_TAG_MAPPING.TROPHY_A,
  TOURNAMENT_GROUP_TAG_MAPPING.TROPHY_B
];

export const TOURNAMENT_PLAYOFF_STAGE = [
  TOURNAMENT_GROUP_TAG_MAPPING.PLAYOFF,
  TOURNAMENT_GROUP_TAG_MAPPING.SECOND_PLAYOFF,
  TOURNAMENT_GROUP_TAG_MAPPING.THIRD_PLAYOFF,
  TOURNAMENT_GROUP_TAG_MAPPING.FOURTH_PLAYOFF,
  TOURNAMENT_GROUP_TAG_MAPPING.SECOND_ROUND,
  TOURNAMENT_GROUP_TAG_MAPPING.CUP_PLAYOFF_DOUBLES,
  TOURNAMENT_GROUP_TAG_MAPPING.CUP_PLAYOFF,
  TOURNAMENT_GROUP_TAG_MAPPING.PLATE_PLAYOFF,
  TOURNAMENT_GROUP_TAG_MAPPING.BOWL_PLAYOFF,
  TOURNAMENT_GROUP_TAG_MAPPING.TROPHY_PLAYOFF,
  TOURNAMENT_GROUP_TAG_MAPPING.SHIELD_PLAYOFF
];

export const TOURNAMENT_FINAL_STAGE = [
  TOURNAMENT_GROUP_TAG_MAPPING.CUP_SEMIFINAL,
  TOURNAMENT_GROUP_TAG_MAPPING.CUP_FINAL,
  TOURNAMENT_GROUP_TAG_MAPPING.PLATE_SEMIFINAL,
  TOURNAMENT_GROUP_TAG_MAPPING.PLATE_FINAL,
  TOURNAMENT_GROUP_TAG_MAPPING.BOWL_SEMIFINAL,
  TOURNAMENT_GROUP_TAG_MAPPING.BOWL_FINAL,
  TOURNAMENT_GROUP_TAG_MAPPING.TROPHY_SEMIFINAL,
  TOURNAMENT_GROUP_TAG_MAPPING.TROPHY_FINAL,
  TOURNAMENT_GROUP_TAG_MAPPING.SHIELD_SEMIFINAL,
  TOURNAMENT_GROUP_TAG_MAPPING.SHIELD_FINAL,
  TOURNAMENT_GROUP_TAG_MAPPING.CUP_SEMIFINAL_DOUBLES,
  TOURNAMENT_GROUP_TAG_MAPPING.CUP_FINAL_DOUBLES
];

export const TOURNAMENT_SCHEDULE_MAPPING_CLIENT_TO_SERVER = {
  ROUND_ROBIN: 'ROUND_ROBIN'
};

export const TOURNAMENT_SCHEDULE = {
  ROUND_ROBIN: 'Round Robin'
};

export const TOURNAMENT_GENDER_SERVER_TO_CLIENT_MAPPING = {
  MALE_ONLY: 'Boys',
  FEMALE_ONLY: 'Girls',
  MIXED: 'Mixed'
};

export const INDIVIDUAL_TOURNAMENT_MAXIMUM_NUMBER_TEAMS_SCHOOL = 1;

export const INDIVIDUAL_TOURNAMENT_DEFAULT_SCORING = [8, 6, 5, 4, 3, 2, 1];
export const TEAM_TOURNAMENT_DEFAULT_SCORING = [
  3, // points for win games
  2, // points for draw with goals games
  1, // points for draw without goals games
  0 // points for lose games
];

export const SUBSTAGES_OF_TOURNAMENT = {
  '1/32': '1/32',
  '1/16': '1/16',
  '1/8': '1/8',
  '1/4': '1/4',
  '1/2': '1/2',
  PLAYOFF: 'PLAYOFF',
  FINAL: 'FINAL'
};

export const SUBSTAGES_OF_TOURNAMENT_SERVER_TO_CLIENT_MAPPING = {
  '1/32': '1/32',
  '1/16': '1/16',
  '1/8': '1/8',
  '1/4': '1/4',
  '1/2': '1/2',
  PLAYOFF: 'Playoff',
  FINAL: 'Final'
};

export const TOURNAMENT_PRIZES = {
  CUP: 'CUP',
  PLATE: 'PLATE',
  BOWL: 'BOWL',
  TROPHY: 'TROPHY',
  SHIELD: 'SHIELD',
  VASE: 'VASE'
};

export const TOURNAMENT_PRIZES_SERVER_TO_CLIENT_MAPPING = {
  CUP: 'Cup',
  PLATE: 'Plate',
  BOWL: 'Bowl',
  TROPHY: 'Trophy',
  SHIELD: 'Shield',
  VASE: 'Vase'
};
