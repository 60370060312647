import * as React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.scss';
import { IconDefinition, library } from '@fortawesome/fontawesome-svg-core';
import {
  faAngleDoubleLeft,
  faAngleDoubleRight,
  faAngleLeft,
  faAngleRight,
  faSort,
  faSortUp,
  faSortDown,
  faFilter,
  faQuoteRight,
  faLink,
  faListUl,
  faListOl,
  faAlignLeft,
  faAlignCenter,
  faAlignRight,
  faCode,
  faUnderline,
  faItalic,
  faBold,
  faSyncAlt,
  faEllipsisH,
  faCaretDown,
  faCaretUp,
  faPlusCircle,
  faMinusCircle,
  faClock,
  faPlus,
  faMinus,
  faChevronDown,
  faChevronUp,
  faChevronRight,
  faArrowRight,
  faArrowLeft,
  faFileCsv,
  faVideo,
  faCamera
} from '@fortawesome/free-solid-svg-icons';
import { faFilePdf as farFilePdf } from '@fortawesome/free-regular-svg-icons';
import { faTwitter } from '@fortawesome/free-brands-svg-icons';
import { Redirect, Route, Switch } from 'react-router';
import * as BPromise from 'bluebird';
import * as propz from 'propz';
import { Login } from 'Src/views/Login/Login';
import { become, logout, logoutReduced, roleout } from '../../helpers/auth/auth';
import {
  clearRole,
  clearSessions,
  getActivePermission,
  getActiveSchoolId,
  getActiveSchoolName,
  getSessions,
  getUserIdFromSession,
  getUserRoleFromSession
} from '../../helpers/session/session';
import { RoleSession } from '../../models/sessions';
import { UserProfile } from '../../models/profile';
import StorageHelper from '../../helpers/storage/storage';
import { GenericView } from '../GenericView/GenericView';
import { getSchool } from '../../helpers/service/admin/user';
import { getProfile, getRoles } from '../../helpers/service/admin/user';
import { Loader } from '../../components/Loader/Loader';
import 'react-datepicker/dist/react-datepicker.css';
import { School } from '../../models/school';
import { Permission } from '../../models/permission';
import { ResetPassword } from '../ResetPassword/ResetPassword';
import { ProfileRouting } from '../GenericView/Profile/ProfileRouting';
import { Registration } from '../Registration/Registration';
import { LoginRoute } from '../LoginRouter/LoginRouter';
import { RoleSelector } from '../LoginRouter/RoleSelector/RoleSelector';
import { ROLE } from '../../consts/user';
import { KIND } from '../../consts/school';

library.add(
  faAngleDoubleLeft,
  faAngleDoubleRight,
  faAngleLeft,
  faAngleRight,
  faSort,
  faSortUp,
  faSortDown,
  faFilter,
  faQuoteRight,
  faCode,
  faBold,
  faItalic,
  faUnderline,
  faLink,
  faListOl,
  faListUl,
  faAlignLeft,
  faAlignRight,
  faAlignCenter,
  faSyncAlt,
  faEllipsisH,
  faCaretDown,
  faCaretUp,
  faPlusCircle,
  faMinusCircle,
  faClock,
  faPlus,
  faMinus,
  faChevronDown,
  faChevronUp,
  faChevronRight,
  faFileCsv,
  farFilePdf,
  faArrowRight,
  faArrowLeft,
  faTwitter as IconDefinition,
  faVideo,
  faCamera
);

interface State {
  user: AppUser;
  isLoading: boolean;
}

export interface AppUser {
  authorized: boolean;
  userId: string;
  loginSessionKey: string;
  roleSessionKey: string;
  activeRole: string;
  activeSchoolId: string;
  activeSchoolName: string;
  activeSchool: School;
  activePermission: Permission;
  profile: UserProfile;
  isRolesNotExist: boolean;
}

export class App extends React.Component<{}, State> {
  constructor(props) {
    super(props);

    const sessions = getSessions();

    const { loginSession, roleSession } = sessions;

    const activeSchoolId = getActiveSchoolId();
    const activeSchoolName = getActiveSchoolName();
    const activePermission = getActivePermission();

    const userId = getUserIdFromSession(loginSession, roleSession);
    const role = getUserRoleFromSession(roleSession);

    const isLoginSession = typeof loginSession !== 'undefined';
    const isRoleSession = typeof roleSession !== 'undefined';
    const isRoleExist = typeof role !== 'undefined';

    this.state = {
      user: {
        loginSessionKey: isLoginSession ? loginSession.id || loginSession.key : undefined,
        roleSessionKey: isRoleSession ? roleSession.key : undefined,
        userId: userId,
        authorized: isLoginSession || isRoleSession,
        activeRole: isRoleExist ? role : undefined,
        activeSchoolId: activeSchoolId,
        activeSchoolName: activeSchoolName,
        activeSchool: undefined,
        activePermission: activePermission,
        profile: undefined,
        isRolesNotExist: isRoleExist ? !isRoleExist : undefined
      },
      isLoading: true
    };
  }

  componentDidMount() {
    const user = this.state.user;
    const { roleSessionKey, activeSchoolId, loginSessionKey } = user;
    const isRoleSessionKeyExist = typeof roleSessionKey !== 'undefined';
    const isLoginSessionKeyExist = typeof loginSessionKey !== 'undefined';

    switch (true) {
      case isRoleSessionKeyExist: {
        this.setState({
          isLoading: true
        });

        const getProfilePromise = getProfile(user);
        const getSchoolPromise = getSchool(user, activeSchoolId);
        const getRolesPromise = getRoles(user);
        const promises = [getProfilePromise, getSchoolPromise, getRolesPromise];

        BPromise.all(promises)
          .then(([profile, school, roles]) => {
            const isRolesNotExist = roles.length === 0;
            this.setState({
              user: {
                ...user,
                activeSchool: school,
                profile,
                isRolesNotExist
              },
              isLoading: false
            });
          })
          .catch(err => {
            this.setDefaultState();
          });
        break;
      }
      case isLoginSessionKeyExist: {
        const getRolesPromise = getRoles(user);
        const getProfilePromise = getProfile(user);
        const promises = [getProfilePromise, getRolesPromise];

        BPromise.all(promises)
          .then(([profile, roles]) => {
            const isRolesNotExist = roles.length === 0;
            this.setState({
              user: {
                ...user,
                profile,
                isRolesNotExist
              },
              isLoading: false
            });
          })
          .catch(err => {
            this.setDefaultState();
          });
        break;
      }
      default: {
        this.setState({
          isLoading: false
        });
      }
    }
  }

  onLoginClick = (userId: string, key: string, profile: UserProfile): void => {
    const user = this.state.user;
    const isEmailVerified = propz.get(profile, ['verification', 'status', 'email']);
    const isPhoneVerified = propz.get(profile, ['verification', 'status', 'sms']);
    const isUserVerified = isEmailVerified && isPhoneVerified;

    this.setState({
      user: {
        ...user,
        authorized: true,
        userId: userId,
        loginSessionKey: key,
        profile,
        isRolesNotExist: !isUserVerified ? true : user.isRolesNotExist
      }
    });
  };

  setDefaultState = (): void => {
    clearSessions();

    this.setState({
      user: {
        loginSessionKey: undefined,
        roleSessionKey: undefined,
        userId: undefined,
        authorized: false,
        activeRole: undefined,
        activeSchoolId: undefined,
        activeSchoolName: undefined,
        activeSchool: undefined,
        activePermission: undefined,
        profile: undefined,
        isRolesNotExist: undefined
      }
    });
  };

  roleOut = () => {
    clearRole();

    const { user } = this.state;
    const { roleSessionKey } = user;

    this.setState({
      isLoading: true
    });

    roleout(roleSessionKey).then(() => {
      this.setState({
        isLoading: false,
        user: {
          ...user,
          roleSessionKey: undefined,
          activeRole: undefined,
          activeSchoolId: undefined,
          activeSchoolName: undefined,
          activeSchool: undefined,
          activePermission: undefined
        }
      });
    });
  };

  onLogoutClick = (event): void => {
    event.preventDefault();

    const { loginSessionKey, roleSessionKey } = this.state.user;
    if (loginSessionKey && roleSessionKey) {
      logout(loginSessionKey, roleSessionKey).then(
        () => {
          this.setDefaultState();
        },
        () => {
          this.setDefaultState(); // TODO: test is double setDefaultState necessary
        }
      );
    } else {
      logoutReduced(loginSessionKey).then(() => {
        this.setDefaultState();
      });
    }
  };

  onRoleChange = (
    roleName: string,
    permissionSchoolId: string,
    permissionSchoolName: string,
    permission: Permission
  ): any => {
    const user = this.state.user;

    StorageHelper.SessionStorage.set('activeSchoolId', permissionSchoolId);
    StorageHelper.SessionStorage.set('activeSchoolName', permissionSchoolName);
    StorageHelper.SessionStorage.set('activePermission', permission);

    const { roleSessionKey } = user;

    this.setState({
      isLoading: true
    });

    return roleout(roleSessionKey)
      .then(() => {
        return become(user, roleName);
      })
      .then((roleSession: RoleSession) => {
        const { role: userRole, key } = roleSession;

        return this.onRoleSelect(userRole, key, permissionSchoolId, permissionSchoolName, roleSession, permission);
      });
  };

  onRoleSelect = (
    roleName: string,
    key: string,
    permissionSchoolId: string,
    permissionSchoolName: string,
    roleSession: RoleSession,
    permission: Permission
  ): void => {
    const user = this.state.user;

    StorageHelper.SessionStorage.set('roleSession', roleSession);
    StorageHelper.SessionStorage.set('activeSchoolId', permissionSchoolId);
    StorageHelper.SessionStorage.set('activeSchoolName', permissionSchoolName);
    StorageHelper.SessionStorage.set('activePermission', permission);

    getSchool(user, permissionSchoolId, key).then(school => {
      this.setState({
        user: {
          ...user,
          roleSessionKey: key,
          activeRole: roleName,
          activeSchoolId: permissionSchoolId,
          activeSchoolName: permissionSchoolName,
          activeSchool: school,
          activePermission: permission,
          isRolesNotExist: false
        },
        isLoading: false
      });
    });
  };

  onSchoolUpdate = () => {
    const user = this.state.user;
    const permissionSchoolId = user.activeSchoolId;
    const key = user.roleSessionKey;

    return getSchool(user, permissionSchoolId, key).then(school => {
      this.setState({
        user: {
          ...user,
          activeSchool: school
        }
      });
    });
  };

  updateProfile = (profile: UserProfile) => {
    const user = this.state.user;
    this.setState({
      user: {
        ...user,
        profile
      }
    });
  };

  updateIsRolesNotExist = (isRolesNotExist: boolean) => {
    const user = this.state.user;
    this.setState({
      user: {
        ...user,
        isRolesNotExist
      }
    });
  };

  render() {
    const { isLoading, user } = this.state;
    const isEmailVerified = propz.get(user, ['profile', 'verification', 'status', 'email']);
    const isPhoneVerified = propz.get(user, ['profile', 'verification', 'status', 'sms']);
    const isUserVerified = isEmailVerified && isPhoneVerified;
    const isUserAuthorized = propz.get(user, ['authorized']);
    const isRolesNotExist = propz.get(user, ['isRolesNotExist']);

    const userActiveRole = propz.get(user, ['activeRole']);
    const isAdmin = userActiveRole === ROLE.ADMIN;
    const userActiveSchoolKind = propz.get(user, ['activeSchool', 'kind']);
    const isSchoolUnion = userActiveSchoolKind === KIND.SCHOOL_UNION;
    const isSchoolUnionAdmin = isAdmin && isSchoolUnion;

    if (isLoading) {
      return <Loader />;
    }

    if (isUserAuthorized && !isUserVerified) {
      return (
        <Switch>
          <Route
            path="/profile"
            component={props => (
              <ProfileRouting
                user={user}
                updateProfile={this.updateProfile}
                onLogoutClick={this.onLogoutClick}
                {...props}
              />
            )}
          />
          <Route
            path="/"
            render={props => <Redirect to={{ pathname: '/profile/verification', state: { from: props.location } }} />}
          />
        </Switch>
      );
    }

    if (isUserAuthorized && isRolesNotExist) {
      return (
        <Switch>
          <Route
            path="/profile"
            component={props => (
              <ProfileRouting
                user={user}
                updateProfile={this.updateProfile}
                onLogoutClick={this.onLogoutClick}
                {...props}
              />
            )}
          />
          <Route
            path="/"
            render={props => <Redirect to={{ pathname: '/profile/requests', state: { from: props.location } }} />}
          />
        </Switch>
      );
    }

    return (
      <Switch>
        <Route
          exact
          path="/login"
          component={props => <Login onFormSubmit={this.onLoginClick} {...props} user={user} />}
        />
        <Route path="/resetPassword" component={props => <ResetPassword {...props} />} />
        <Route
          path="/registration"
          component={props => <Registration {...props} user={user} onFormSubmit={this.onLoginClick} />}
        />
        <LoginRoute
          exact
          path="/role"
          component={props => (
            <RoleSelector
              user={user}
              onRoleSelect={this.onRoleSelect}
              setDefaultState={this.setDefaultState}
              updateIsRolesNotExist={this.updateIsRolesNotExist}
              {...props}
            />
          )}
          user={user}
        />

        <Route
          exact
          path="/"
          render={props =>
            user.activeRole ? (
              <Redirect
                to={{
                  pathname: isSchoolUnionAdmin ? '/tournaments/upcoming' : '/calendar',
                  state: {
                    from: {
                      pathname: props.location.pathname,
                      search: props.location.search
                    }
                  }
                }}
              />
            ) : (
              <Redirect
                to={{
                  pathname: '/role',
                  state: {
                    from: {
                      pathname: props.location.pathname,
                      search: props.location.search
                    }
                  }
                }}
              />
            )
          }
        />

        <Route
          path="/"
          render={props =>
            user.activeRole ? (
              <GenericView
                onRoleChange={this.onRoleChange}
                onLogoutClick={this.onLogoutClick}
                user={user}
                onSchoolUpdate={this.onSchoolUpdate}
                updateProfile={this.updateProfile}
                setAppDefaultState={this.setDefaultState}
                roleOut={this.roleOut}
                {...props}
              />
            ) : (
              <Redirect
                to={{
                  pathname: '/role',
                  state: {
                    from: {
                      pathname: props.location.pathname,
                      search: props.location.search
                    }
                  }
                }}
              />
            )
          }
        />
      </Switch>
    );
  }
}
