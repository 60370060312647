import { AppUser } from 'Src/views/App/App';
import axios from 'axios';
import { DEFAULT_LIMIT, DEFAULT_SKIP } from 'Src/consts/table';
import { Count } from '../../../models/count';
import { SchoolForm } from '../../../models/form';

const DEFAULT_FILTER: any = {
  limit: DEFAULT_LIMIT,
  skip: DEFAULT_SKIP
};

export function getForms(user: AppUser, filter = DEFAULT_FILTER): Promise<SchoolForm[]> {
  const { roleSessionKey: key, activeSchoolId } = user;

  const config = {
    headers: { usid: key },
    params: {
      filter: {
        where: {
          ...filter.where
        },
        skip: filter.skip,
        limit: filter.limit,
        order: filter.order
      }
    }
  };

  return axios.get(`${window.apiBase}/i/schools/${activeSchoolId}/forms`, config).then(response => {
    return response.data;
  });
}

export function getAllForms(user: AppUser, where?): Promise<SchoolForm[]> {
  const { roleSessionKey: key, activeSchoolId } = user;

  let config;

  if (typeof where !== 'undefined') {
    config = {
      headers: { usid: key },
      params: {
        filter: {
          where: {
            ...where
          },
          limit: DEFAULT_LIMIT,
          skip: DEFAULT_SKIP
        }
      }
    };
  } else {
    config = {
      headers: { usid: key },
      params: {
        filter: {
          limit: DEFAULT_LIMIT,
          skip: DEFAULT_SKIP
        }
      }
    };
  }

  return axios.get<SchoolForm[]>(`${window.apiBase}/i/schools/${activeSchoolId}/forms`, config).then(response => {
    return response.data;
  });
}

export function getFormCount(user: AppUser, where?): Promise<Count> {
  const { roleSessionKey: key, activeSchoolId } = user;

  let config;

  if (typeof where !== 'undefined') {
    config = {
      headers: { usid: key },
      params: {
        filter: {
          where: where
        }
      }
    };
  } else {
    config = {
      headers: { usid: key }
    };
  }

  return axios.get<Count>(`${window.apiBase}/i/schools/${activeSchoolId}/forms/count`, config).then(response => {
    return response.data;
  });
}

export function createForm(user: AppUser, form: any) {
  const { roleSessionKey: key, activeSchoolId } = user;
  const config = {
    headers: { usid: key }
  };

  return axios.post(`${window.apiBase}/i/schools/${activeSchoolId}/forms`, form, config).then(response => {
    return response.data;
  });
}

export function updateForm(user: AppUser, id: string, form: any) {
  const { roleSessionKey: key, activeSchoolId } = user;
  const config = {
    headers: { usid: key }
  };

  return axios.put(`${window.apiBase}/i/schools/${activeSchoolId}/forms/${id}`, form, config).then(response => {
    return response.data;
  });
}

export function deleteForm(user: AppUser, formsId) {
  const { roleSessionKey: key, activeSchoolId } = user;
  const config = {
    headers: { usid: key }
  };

  return axios.delete(`${window.apiBase}/i/schools/${activeSchoolId}/forms/${formsId}`, config).then(response => {
    return response.data;
  });
}

export function checkAvailabilityFormName(user: AppUser, data) {
  const { roleSessionKey: key, activeSchoolId } = user;
  const config = {
    headers: { usid: key }
  };
  return axios.post(`${window.apiBase}/i/schools/${activeSchoolId}/forms/check`, data, config).then(response => {
    return response.data;
  });
}