import { SelectOption } from '../helpers/table/table';
import { CLUB_AUTOENROLLMENT_TYPE } from '../types/club';
import { SCHOOL_CLUB_INVITATIONS_TYPE } from '../types/school';

export const CLUB_STATUS = {
  DRAFT: 'DRAFT', // created but not ready to go public now
  ACTIVE: 'ACTIVE', // yes, it is active right now
  REMOVED: 'REMOVED', // removed. Not visible to user
  ARCHIVED: 'ARCHIVED' // it was active in some time at past, but not active anymore.
};

export const CLUB_STATUS_SERVER_TO_CLIENT_MAPPING = {
  DRAFT: 'Draft',
  ACTIVE: 'Active',
  REMOVED: 'Removed',
  ARCHIVED: 'Archived'
};

export function getClubStatusList(): SelectOption[] {
  let statusOptions = [];

  for (let status in CLUB_STATUS) {
    statusOptions.push({
      text: CLUB_STATUS_SERVER_TO_CLIENT_MAPPING[status],
      value: CLUB_STATUS[status]
    });
  }

  return statusOptions;
}

export const CLUB_PRICING = {
  FREE: 'FREE',
  PER_SESSION: 'PER_SESSION',
  HALF_TERM: 'HALF_TERM',
  TERM: 'TERM',
  DONATION: 'DONATION',
  DONATION_INDICATIVE_PRICE: 'DONATION (indicative price)'
};

export const CLUB_PRICING_SERVER_TO_CLIENT_MAPPING = {
  FREE: 'Free',
  PER_SESSION: 'Per session',
  HALF_TERM: 'Half-term',
  TERM: 'Term',
  DONATION: 'Donation'
};

export const CLUB_CURRENCY = {
  POUND: 'POUND'
};

export const CLUB_CURRENCY_SYMBOL = {
  POUND: '£'
};

export const CLUB_SCHEDULE = {
  DATES_LIST: 'DATES_LIST',
  INTERVALS: 'INTERVALS',
  WEEKLY_SCHEDULE: 'WEEKLY_SCHEDULE'
};

export const CLUB_SCHEDULE_SERVER_TO_CLIENT_MAPPING = {
  DATES_LIST: 'Dates',
  INTERVALS: 'Intervals',
  WEEKLY_SCHEDULE: 'Weekly schedule'
};

export const CLUB_STAFF = {
  COACH: 'COACH',
  MEMBER_OF_STAFF: 'MEMBER_OF_STAFF' // just generic value
};

export const CLUB_COACH_LIST = ['COACH', 'TEACHER'];

export const CLUB_MEMBER_OF_STAFF_LIST = ['ADMIN', 'MANAGER', 'TEACHER'];

export const CLUB_MESSAGE_INVITE_STATUS_ALL = {
  ACCEPTED: 'ACCEPTED', // those who received invite accepted sender's invitation
  REJECTED: 'REJECTED', // those who received invite rejected sender's invitation
  PREBOOKED: 'PREBOOKED', // invite booked. still await final status ACCEPTED or REJECTED
  PENDING: 'PENDING', // no decision made on this invite
  OUTDATED: 'OUTDATED', // invite was mark as outdated by system cron script
  WAITING_LIST: 'WAITING_LIST',
  WAITING_LIST_NOT_BOOKED: 'WAITING_LIST_NOT_BOOKED',
  WAITING_LIST_CAN_BOOK: 'WAITING_LIST_CAN_BOOK',
  AUTHORISING: 'AUTHORISING',
  REFUNDED: 'REFUNDED',
  NOT_SENT: 'NOT_SENT' // invite was not sent by sender
};

export const CLUB_MESSAGE_INVITE_STATUS_SERVER_TO_CLIENT_MAPPING = {
  ACCEPTED: 'Accepted',
  REJECTED: 'Rejected',
  PREBOOKED: 'Prebooked',
  PENDING: 'Pending',
  OUTDATED: 'Outdated',
  WAITING_LIST: 'Waiting list',
  WAITING_LIST_NOT_BOOKED: 'Waiting list not booked',
  WAITING_LIST_CAN_BOOK: 'Waiting list can book',
  AUTHORISING: 'Authorising',
  REFUNDED: 'Refunded',
  NOT_SENT: 'Not sent'
};

export const CLUB_MESSAGE_INVITE_STATUS_IF_PAYMENTS_DISABLED = {
  ACCEPTED: 'ACCEPTED',
  REJECTED: 'REJECTED',
  PENDING: 'PENDING',
  OUTDATED: 'OUTDATED',
  WAITING_LIST: 'WAITING_LIST',
  WAITING_LIST_NOT_BOOKED: 'WAITING_LIST_NOT_BOOKED',
  WAITING_LIST_CAN_BOOK: 'WAITING_LIST_CAN_BOOK',
  NOT_SENT: 'NOT_SENT'
};

export const CLUB_MESSAGE_INVITE_STATUS_IF_PAYMENTS_DISABLED_SERVER_TO_CLIENT_MAPPING = {
  ACCEPTED: 'Accepted',
  REJECTED: 'Rejected',
  PENDING: 'Pending',
  OUTDATED: 'Outdated',
  WAITING_LIST: 'Waiting list',
  WAITING_LIST_NOT_BOOKED: 'Waiting list not booked',
  WAITING_LIST_CAN_BOOK: 'Waiting list can book',
  NOT_SENT: 'Not sent'
};

export const CLUB_TABS = {
  SUMMARY: 'Summary',
  PARTICIPANT: 'Participants',
  CLUB_EVENTS: 'Club events',
  PAYMENTS: 'Payments',
  PAYMENT_SETTINGS: 'Payment settings'
};

export const CLUB_GENDER_SERVER_TO_CLIENT_MAPPING = {
  MALE_ONLY: 'Boys',
  FEMALE_ONLY: 'Girls',
  MIXED: 'Mixed'
};

export const CLUB_TABS_ARRAY = [
  CLUB_TABS.SUMMARY,
  CLUB_TABS.PARTICIPANT,
  CLUB_TABS.CLUB_EVENTS,
  CLUB_TABS.PAYMENTS,
  CLUB_TABS.PAYMENT_SETTINGS
];

export const CLUB_TABS_FOR_SCHOOL_WITHOUT_PAYMENT = ['Summary', 'Participants', 'Club events'];

export const CLUB_INVITATIONS: {
  [key: string]: SCHOOL_CLUB_INVITATIONS_TYPE;
} = {
  PARENT: 'PARENT',
  STUDENT: 'STUDENT'
};

export const CLUB_INVITATIONS_SERVER_TO_CLIENT_MAPPING = {
  PARENT: 'Parents',
  STUDENT: 'Students'
};

export const CLUB_AUTOENROLLMENT: {
  [key: string]: CLUB_AUTOENROLLMENT_TYPE;
} = {
  ON: 'ON',
  OFF: 'OFF'
};

export const CLUB_AUTOENROLLMENT_SERVER_TO_CLIENT_MAPPING = {
  ON: 'On',
  OFF: 'Off'
};

export const MAX_CLUB_PRIORITY = 10;
export const MIN_CLUB_PRIORITY = 1;

export const CLUB_CONFIRMATION_REQUEST_TABS = ['Answers'];

export const CLUB_ACADEMIC_YEARS_MAX = 2024;
export const CLUB_ACADEMIC_YEARS_MIN = 2015;
