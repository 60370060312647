import { PHONE_CODE } from '../../consts/common';

export function getPhoneAndCode(phone) {
  const isPhoneExist = typeof phone !== 'undefined' && phone !== '';
  if (!isPhoneExist) {
    return { code: '', phone: '' };
  }

  let phoneBody;
  switch (true) {
    case phone.startsWith(PHONE_CODE.RU):
      phoneBody = phone.substr(PHONE_CODE.RU.length);

      return { code: PHONE_CODE.RU, phone: phoneBody };
    case phone.startsWith(PHONE_CODE.UK):
      phoneBody = phone.substr(PHONE_CODE.UK.length);

      return { code: PHONE_CODE.UK, phone: phoneBody };
    case phone.startsWith(PHONE_CODE.US):
      phoneBody = phone.substr(PHONE_CODE.US.length);

      return { code: PHONE_CODE.US, phone: phoneBody };
    case phone.startsWith(PHONE_CODE.AE):
      phoneBody = phone.substr(PHONE_CODE.AE.length);

      return { code: PHONE_CODE.AE, phone: phoneBody };
    case phone.startsWith(PHONE_CODE.DE):
      phoneBody = phone.substr(PHONE_CODE.DE.length);

      return { code: PHONE_CODE.DE, phone: phoneBody };
  }
}

export function getUKPhoneWithoutFirstZero(phone, phoneCode) {
  const isExistPhone = typeof phone !== 'undefined' && phone !== '';
  const isUKPhoneCode = phoneCode === PHONE_CODE.UK;
  if (isExistPhone && isUKPhoneCode) {
    const regExp = /^0*/;
    return phone.replace(regExp, '');
  } else {
    return phone;
  }
}
