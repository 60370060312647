import * as React from 'react';
import * as BPromise from 'bluebird';
import * as Lazy from 'lazy.js';
import { AppUser } from 'Src/views/App/App';
import { parse } from 'query-string';
import { getOrder, getSearchFilter, getSearchOrder, getServerFieldSectionWhere } from 'Src/helpers/table/table';
import {
  ColumnDefinition,
  getFilters2,
  getServerQueryFilter2,
  isFilterExist2,
  isSortExist,
  TABLE_SORT_DIRECTION
} from 'Src/helpers/table/table';
import { History, Location } from 'history';
import { Grid2 } from 'Src/components/Grid/Grid2';
import { Loader } from 'Src/components/Loader/Loader';
import { DATE_INTERVAL, DEFAULT_LIMIT, DEFAULT_SKIP, FILTER_TYPE, FIRST_PAGE } from 'Src/consts/table';
import { getTournament } from 'Src/helpers/service/adminSU/tournamentsTable';
import { Tournament } from 'Src/models/tournament';
import { getTeamAgesGroup, getTeamGender } from 'Src/helpers/accessor/accessor';
import { getSelectOptionForTournamentTeamAges, getSelectOptionForTournamentTeamGender } from 'Src/helpers/table/select';
import {
  getAllSchoolUnionTournamentTeams,
  getSchoolUnionTournamentTeams,
  getSchoolUnionTournamentTeamsCount
} from 'Src/helpers/service/adminSU/tournamentTeamsTable';
import { SimpleModal } from 'Src/components/SimpleModal/SimpleModal';
import {
  addSchoolTournamentTeam,
  editSchoolTournamentTeam,
  removeSchoolTournamentTeam
} from 'Src/helpers/service/admin/tournamentTeams';
import { AdminSUTournamentTeamForm } from '../TournamentTeamForm/TournamentTeamForm';
import { TEAM_TYPE } from 'Src/consts/team';
import { TournamentTeam } from 'Src/models/tournamentTeam';
import {
  getTournamentResultTeams,
  updateTournamentResultTeams,
  createTournamentResultTeams
} from '../../../../../helpers/service/adminSU/tournamentTeamsTable';
import * as propz from 'propz';
import { TOURNAMENT_SUBTYPE } from '../../../../../consts/tournament';

const COLUMNS: ColumnDefinition[] = [
  {
    text: 'School Name',
    field: 'schoolName',
    isSort: false,
    type: FILTER_TYPE.NONE,
    accessor: ['school', 'name']
  },
  {
    text: 'Team Name',
    field: 'name',
    isSort: false,
    type: FILTER_TYPE.TEXT,
    accessor: ['name']
  },
  {
    text: 'Gender',
    field: 'gender',
    isSort: false,
    type: FILTER_TYPE.MULTISELECT,
    accessor: getTeamGender
  },
  {
    text: 'Age',
    field: 'teamAges',
    isSort: false,
    type: FILTER_TYPE.MULTISELECT,
    accessor: getTeamAgesGroup
  }
];

interface State {
  items: TournamentTeam[];
  currentPage: number;
  selectedItems: TournamentTeam[];
  sortDirection: TABLE_SORT_DIRECTION;
  sortColumnsName: string;
  isShowFilter: boolean;
  filters: any;
  isDataFiltered: boolean;
  isLoading: boolean;
  total: number;
  basePath: string;
  isSelectAllChecked: boolean;
  tournament: Tournament;

  isDeleteConfirmWindowOpen: boolean;
  isTeamFormWindowOpen: boolean;
  isDeleteAlertWindowOpen: boolean;
  allTournamentTeams: TournamentTeam[];
  result: any;
}

interface Props {
  user: AppUser;
  history: History;
  location: Location;
}

export class AdminSUTournamentTeams extends React.Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      items: [],
      currentPage: FIRST_PAGE,
      selectedItems: [],
      sortDirection: '',
      sortColumnsName: '',
      isShowFilter: false,
      filters: {},
      isDataFiltered: false,
      isLoading: false,
      total: 0,
      basePath: '',
      isSelectAllChecked: false,
      tournament: undefined,

      isDeleteConfirmWindowOpen: false,
      isTeamFormWindowOpen: false,
      isDeleteAlertWindowOpen: false,
      allTournamentTeams: [],
      result: undefined
    };
  }

  componentDidMount() {
    this.setState({
      isLoading: true
    });

    this.setAdditionalItems().then(res => {
      this.setItems();
    });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.location.search !== this.props.location.search) {
      this.setState({
        isLoading: true
      });
      this.setItems();
    }
  }

  getTournamentId(): string {
    const { history } = this.props;
    const search = parse(history.location.search);
    const tournamentId = search.tournament;
    return tournamentId;
  }

  setAdditionalItems() {
    const { user } = this.props;

    const tournamentId = this.getTournamentId();

    return getTournament(user, tournamentId).then(tournament => {
      this.setState({
        tournament: tournament
      });

      return true;
    });
  }

  setItems() {
    const { history, user } = this.props;

    const search = parse(history.location.search);
    const page = typeof search.page !== 'undefined' ? Number(search.page) : FIRST_PAGE;

    let sortDirection: TABLE_SORT_DIRECTION = '';
    let sortColumnsName = '';

    if (typeof search.order === 'string') {
      [sortColumnsName, sortDirection] = search.order.split(':');
    }

    const sortByFieldExist = sortColumnsName !== '';
    const sortDirectionExist = sortDirection !== '';

    const filters = getFilters2(COLUMNS, search);

    const isShowFilter = isFilterExist2(filters);
    const isDataFiltered = isFilterExist2(filters);

    const where = getServerFieldSectionWhere(filters);

    const order = sortByFieldExist && sortDirectionExist ? `${sortColumnsName} ${sortDirection}` : undefined;
    const serverQueryFilter = getServerQueryFilter2(page, where, order);

    const tournamentId = this.getTournamentId();

    const getItemsPromise = getSchoolUnionTournamentTeams(user, tournamentId, serverQueryFilter);
    const getItemsCountPromise = getSchoolUnionTournamentTeamsCount(user, tournamentId, where);

    return BPromise.all([getItemsCountPromise, getItemsPromise]).then(([countObj, items]) => {
      this.setState({
        currentPage: page,
        sortDirection: sortDirection,
        sortColumnsName: sortColumnsName,
        isShowFilter: isShowFilter,
        isDataFiltered: isDataFiltered,
        filters: filters,
        items: items,
        total: countObj.count,
        basePath: history.location.pathname,
        isLoading: false
      });

      return true;
    });
  }

  setCurrentPageParams = (currentPage: number): void => {
    let search = [];

    const { filters, sortColumnsName, sortDirection } = this.state;
    const { history } = this.props;

    if (currentPage !== 1) {
      search.push(`page=${currentPage}`);
    }

    if (isSortExist(sortDirection, sortColumnsName)) {
      search.push(getSearchOrder(sortDirection, sortColumnsName));
    }

    const isFilter = isFilterExist2(filters);

    if (isFilter) {
      search.push(getSearchFilter(filters));
    }

    const tournamentId = this.getTournamentId();

    this.props.history.push({
      pathname: this.state.basePath,
      search: `tournament=${tournamentId}&${search.join('&')}`,
      state: history.location.state
    });
  };

  onItemClick = (index: number): void => {
    const { items, selectedItems } = this.state;
    const selectedItem = items[index];

    const selectedItemIndex = selectedItems.findIndex(item => selectedItem.id === item.id);
    let selectedItemsUpdated = [...selectedItems];

    if (selectedItemIndex !== -1) {
      selectedItemsUpdated.splice(selectedItemIndex, 1);
    } else {
      selectedItemsUpdated.push(selectedItem);
    }

    this.setState({
      selectedItems: selectedItemsUpdated
    });
  };

  onSelectAllOnPageClick = (): void => {
    const { items, selectedItems } = this.state;

    const itemsFiltered = items.filter(item => {
      return selectedItems.every(selectedItem => selectedItem.id !== item.id);
    });

    const nextSelectedItems = [...selectedItems, ...itemsFiltered];

    this.setState({
      selectedItems: nextSelectedItems,
      isSelectAllChecked: true
    });
  };

  onSelectAllClick = (): void => {
    const { selectedItems, filters } = this.state;

    this.setState({
      isLoading: true
    });

    const user = this.props.user;

    const where = getServerFieldSectionWhere(filters);

    const tournamentId = this.getTournamentId();

    getAllSchoolUnionTournamentTeams(user, tournamentId, where).then(items => {
      const itemsFiltered = items.filter(item => {
        return selectedItems.every(selectedItem => selectedItem.id !== item.id);
      });

      const nextSelectedItems = [...selectedItems, ...itemsFiltered];

      this.setState({
        selectedItems: nextSelectedItems,
        isSelectAllChecked: true,
        isLoading: false
      });
    });
  };

  onUnselectAllClick = (): void => {
    this.setState({
      selectedItems: [],
      isSelectAllChecked: false
    });
  };

  onTableSortClick = (sortField: string): void => {
    const { sortDirection, sortColumnsName, filters } = this.state;
    const { history } = this.props;

    const order = getOrder(sortField, sortDirection, sortColumnsName);

    let search = [];
    search.push(`order=${sortField}:${order}`);

    const isFilter = isFilterExist2(filters);

    if (isFilter) {
      search.push(getSearchFilter(filters));
    }

    const tournamentId = this.getTournamentId();

    this.props.history.push({
      pathname: this.state.basePath,
      search: `tournament=${tournamentId}&${search.join('&')}`,
      state: history.location.state
    });
  };

  onTableFilterChange = (event, filterField: string, options?): void => {
    const filterValue = event.target.value;
    const filters = this.state.filters;
    const currentFilterField = filters[filterField];

    let nextFilters = { ...filters };

    if (typeof options !== 'undefined') {
      switch (options) {
        case DATE_INTERVAL.FROM:
          nextFilters = {
            ...nextFilters,
            [filterField]: {
              ...currentFilterField,
              from: filterValue
            }
          };
          break;
        case DATE_INTERVAL.TO:
          nextFilters = {
            ...nextFilters,
            [filterField]: {
              ...currentFilterField,
              to: filterValue
            }
          };
          break;
      }
    } else {
      const filter = COLUMNS.find(col => col.field === filterField);
      const filterType = filter.type;

      if (filterType === FILTER_TYPE.MULTISELECT) {
        const options = event.target.options;
        const value = [];
        for (let i = 0; i < options.length; i++) {
          if (options[i].selected) {
            value.push(options[i].value);
          }
        }
        nextFilters = {
          ...nextFilters,
          [filterField]: value
        };
      } else {
        nextFilters = {
          ...nextFilters,
          [filterField]: filterValue
        };
      }
    }

    this.setState({
      filters: nextFilters
    });
  };

  onApplyFilterClick = (): void => {
    const { filters, sortDirection, sortColumnsName } = this.state;
    const { history } = this.props;
    let search = [];

    if (isSortExist(sortDirection, sortColumnsName)) {
      search.push(getSearchOrder(sortDirection, sortColumnsName));
    }

    const isFilter = isFilterExist2(filters);

    if (isFilter) {
      search.push(getSearchFilter(filters));
    }

    this.setState({
      selectedItems: [],
      isSelectAllChecked: false
    });

    const tournamentId = this.getTournamentId();

    this.props.history.push({
      pathname: this.state.basePath,
      search: `tournament=${tournamentId}&${search.join('&')}`,
      state: history.location.state
    });
  };

  onClearFilterClick = (): void => {
    const { history } = this.props;
    this.setState({
      selectedItems: [],
      isSelectAllChecked: false
    });

    const tournamentId = this.getTournamentId();

    this.props.history.push({
      pathname: this.state.basePath,
      search: `tournament=${tournamentId}`,
      state: history.location.state
    });
  };

  onTableFilterClick = (event): void => {
    event.preventDefault();

    const isShowFilter = this.state.isShowFilter;

    this.setState({
      isShowFilter: !isShowFilter
    });
  };

  isOneItemSelected(): boolean {
    const selectedItems = this.state.selectedItems;
    return selectedItems.length === 1;
  }

  goBack = () => {
    const { history, location } = this.props;
    const { state } = location;
    const { search, prevPath } = state as any;
    const tournamentId = this.getTournamentId();

    history.push({
      pathname: prevPath,
      search: search,
      state: { tournamentId: tournamentId }
    });
  };

  renderGrid() {
    const {
      items,
      sortDirection,
      sortColumnsName,
      isShowFilter,
      isDataFiltered,
      selectedItems,
      filters,
      isSelectAllChecked,
      currentPage,
      total,
      tournament
    } = this.state;

    const { user } = this.props;

    const actionItems = [
      {
        itemText: 'Add team',
        onItemClick: this.onAddTeamClick,
        isActive: selectedItems.length === 0
      },
      {
        itemText: 'Edit team',
        onItemClick: this.onEditTeamClick,
        isActive: selectedItems.length === 1
      },
      {
        itemText: selectedItems.length === 1 ? 'Delete team' : 'Delete teams',
        onItemClick: this.onDeleteTeamClick,
        isActive: selectedItems.length > 0
      }
    ];

    const filterOptions = {
      gender: getSelectOptionForTournamentTeamGender(),
      teamAges: getSelectOptionForTournamentTeamAges(tournament, user)
    };
    const gridTitle = typeof tournament !== 'undefined' ? `${tournament.name} / Teams` : '';

    return (
      <Grid2
        dataItems={items}
        filters={filters}
        currentPage={currentPage}
        total={total}
        isSelectAllChecked={isSelectAllChecked}
        isDataFiltered={isDataFiltered}
        sortDirection={sortDirection}
        sortColumnsName={sortColumnsName}
        isShowFilter={isShowFilter}
        dataItemsSelected={selectedItems}
        columns={COLUMNS}
        actionItems={actionItems}
        options={filterOptions}
        onItemClick={this.onItemClick}
        onSortClick={this.onTableSortClick}
        onApplyFilterClick={this.onApplyFilterClick}
        onClearFilterClick={this.onClearFilterClick}
        onTableFilterChange={this.onTableFilterChange}
        onTableFilterClick={this.onTableFilterClick}
        setCurrentPageParams={this.setCurrentPageParams}
        onSelectAllClick={this.onSelectAllClick}
        onSelectAllOnPageClick={this.onSelectAllOnPageClick}
        onUnselectAllClick={this.onUnselectAllClick}
        gridTitle={gridTitle}
        goBack={this.goBack}
        user={user}
      />
    );
  }

  onAddTeamFormSubmit = (data: any) => {
    const { name, schoolId } = data;
    const { tournament } = this.state;
    const { user } = this.props;

    const { sportId } = tournament;
    const tournamentId = this.getTournamentId();
    const tournamentSubtype = propz.get(tournament, ['subType']);
    const winnersOnlyTournament = tournamentSubtype === TOURNAMENT_SUBTYPE.WINNERS_ONLY;

    this.setState({
      isLoading: true,
      isTeamFormWindowOpen: false
    });

    const { age, gender, group, point, description } = data;
    const teamData = {
      name,
      gender,
      sportId,
      schoolId,
      teamType: TEAM_TYPE.ADHOC,
      players: [],
      ages: [Number(age)]
    };

    const resultData = {
      group: group,
      point,
      description
    };

    let team: TournamentTeam;
    addSchoolTournamentTeam(user, tournamentId, teamData)
      .then(_team => {
        team = _team;
        if (winnersOnlyTournament) {
          return createTournamentResultTeams(user, tournamentId, team.id, resultData);
        } else {
          return BPromise.resolve(undefined);
        }
      })
      .then(result => {
        this.setState({
          isLoading: false,
          selectedItems: [team]
        });
        this.setAdditionalItems().then(res => {
          this.setItems();
        });
      });
  };

  onTeamFormCancelClick = () => {
    this.setState({
      isTeamFormWindowOpen: false
    });
  };

  onEditTeamFormSubmit = (data: any) => {
    const { name } = data;
    const { user } = this.props;
    const { tournament } = this.state;
    const tournamentId = this.getTournamentId();
    const tournamentSubtype = propz.get(tournament, ['subType']);
    const winnersOnlyTournament = tournamentSubtype === TOURNAMENT_SUBTYPE.WINNERS_ONLY;

    const { selectedItems } = this.state;
    const team = selectedItems[0];
    const { id: teamId } = team;

    this.setState({
      isLoading: true,
      isTeamFormWindowOpen: false
    });

    const { age, gender, group, point, description } = data;

    const teamData = {
      name,
      gender,
      ages: [Number(age)]
    };

    const resultData = {
      group: group,
      point,
      description
    };

    let teams: TournamentTeam[];
    editSchoolTournamentTeam(user, tournamentId, teamId, teamData)
      .then(_teams => {
        teams = _teams;

        if (winnersOnlyTournament) {
          return updateTournamentResultTeams(user, tournamentId, teamId, resultData);
        } else {
          return BPromise.resolve(undefined);
        }
      })
      .then(result => {
        this.setState({
          isLoading: false,
          selectedItems: [team]
        });
        this.setAdditionalItems().then(res => {
          this.setItems();
        });
      });
  };

  renderTeamFormModal(): React.ReactNode {
    const { isTeamFormWindowOpen, selectedItems, tournament, allTournamentTeams, result } = this.state;
    const { user } = this.props;

    if (selectedItems.length === 0) {
      return (
        <SimpleModal isOpen={isTeamFormWindowOpen} title={'Add team'}>
          <AdminSUTournamentTeamForm
            onCancel={this.onTeamFormCancelClick}
            user={user}
            onSubmit={this.onAddTeamFormSubmit}
            tournament={tournament}
            allTournamentTeams={allTournamentTeams}
            result={result}
          />
        </SimpleModal>
      );
    } else {
      const team = selectedItems[0];
      return (
        <SimpleModal isOpen={isTeamFormWindowOpen} title={'Edit team'}>
          <AdminSUTournamentTeamForm
            onCancel={this.onTeamFormCancelClick}
            user={user}
            onSubmit={this.onEditTeamFormSubmit}
            tournament={tournament}
            team={team}
            allTournamentTeams={allTournamentTeams}
            result={result}
          />
        </SimpleModal>
      );
    }
  }

  getAllSchoolUnionTournamentTeamsPromise() {
    const { user } = this.props;
    const tournamentId = this.getTournamentId();

    return getAllSchoolUnionTournamentTeams(user, tournamentId);
  }

  onAddTeamClick = () => {
    this.setState({
      isLoading: true
    });

    this.getAllSchoolUnionTournamentTeamsPromise().then(teams => {
      this.setState({
        isTeamFormWindowOpen: true,
        isLoading: false,
        allTournamentTeams: teams
      });
    });
  };

  onEditTeamClick = () => {
    const { user } = this.props;
    const { tournament } = this.state;
    const tournamentId = this.getTournamentId();
    const { selectedItems } = this.state;
    const team = selectedItems[0];
    const { id: teamId } = team;
    const tournamentSubtype = propz.get(tournament, ['subType']);
    const winnersOnlyTournament = tournamentSubtype === TOURNAMENT_SUBTYPE.WINNERS_ONLY;

    this.setState({
      isLoading: true
    });

    let teams: TournamentTeam[];

    this.getAllSchoolUnionTournamentTeamsPromise()
      .then(_teams => {
        teams = _teams;

        if (winnersOnlyTournament) {
          return getTournamentResultTeams(user, tournamentId, teamId);
        } else {
          return BPromise.resolve(undefined);
        }
      })
      .then(result => {
        this.setState({
          isTeamFormWindowOpen: true,
          isLoading: false,
          allTournamentTeams: teams,
          result: result
        });
      });
  };

  onDeleteConfirmWindowOkClick = () => {
    const { selectedItems } = this.state;
    const { user } = this.props;
    const tournamentId = this.getTournamentId();

    this.setState({
      isLoading: true,
      isDeleteConfirmWindowOpen: false,
      selectedItems: [],
      isSelectAllChecked: false
    });

    BPromise.all(
      selectedItems.map(team => {
        return removeSchoolTournamentTeam(user, tournamentId, team.id);
      })
    ).then(res => {
      this.setItems();
    });
  };

  onDeleteConfirmWindowCloseClick = () => {
    this.setState({
      isDeleteConfirmWindowOpen: false
    });
  };

  onDeleteTeamClick = () => {
    const { selectedItems: teamsDeleted } = this.state;
    const { user } = this.props;
    const tournamentId = this.getTournamentId();

    const schoolIds = Lazy(teamsDeleted)
      .map(team => team.schoolId)
      .uniq()
      .toArray();

    this.setState({
      isLoading: true
    });

    const filter = {
      where: { schoolId: { $in: schoolIds } },
      skip: DEFAULT_SKIP,
      limit: DEFAULT_LIMIT
    };

    getSchoolUnionTournamentTeams(user, tournamentId, filter).then(allTeams => {
      const isDeleteAvailable = schoolIds.every(schoolId => {
        const schoolTeamsDeleted = teamsDeleted.filter(team => team.schoolId === schoolId);
        const allSchoolTeams = allTeams.filter(team => team.schoolId === schoolId);
        const schoolTeamsDeletedCount = schoolTeamsDeleted.length;
        const allSchoolTeamsCount = allSchoolTeams.length;
        const diffCount = allSchoolTeamsCount - schoolTeamsDeletedCount;

        return diffCount > 0;
      });

      if (isDeleteAvailable) {
        this.setState({
          isDeleteConfirmWindowOpen: true,
          isLoading: false
        });
      } else {
        this.setState({
          isDeleteAlertWindowOpen: true,
          isLoading: false
        });
      }
    });
  };

  onDeleteAlertWindowOkClick = () => {
    this.setState({
      isDeleteAlertWindowOpen: false
    });
  };

  renderDeleteAlertWindow(): React.ReactNode {
    const { isDeleteAlertWindowOpen } = this.state;

    return (
      <SimpleModal
        isOpen={isDeleteAlertWindowOpen}
        title={'Alert'}
        body={
          "The school only has one team in this event and therefore you can't remove it, if you would like to remove the school from the tournament then please go to the Participant list and remove it from there."
        }
        buttonText={'Ok'}
        onButtonClick={this.onDeleteAlertWindowOkClick}
      />
    );
  }

  renderDeleteConfirmWindow(): React.ReactNode {
    const { isDeleteConfirmWindowOpen, selectedItems } = this.state;

    return (
      <SimpleModal
        isOpen={isDeleteConfirmWindowOpen}
        title={'Confirm'}
        body={`Are you sure you want to remove this ${selectedItems.length === 1 ? 'team' : 'teams'} from tournament?`}
        buttonText={'Ok'}
        onButtonClick={this.onDeleteConfirmWindowOkClick}
        onCloseClick={this.onDeleteConfirmWindowCloseClick}
      />
    );
  }

  render() {
    const { isLoading, isDeleteConfirmWindowOpen, isDeleteAlertWindowOpen, isTeamFormWindowOpen } = this.state;

    if (isLoading) {
      return <Loader />;
    }

    const classes =
      isDeleteConfirmWindowOpen || isTeamFormWindowOpen || isDeleteAlertWindowOpen ? 'mt-3 modal-open' : 'mt-3';

    return (
      <div className={classes}>
        {/*Place for render modal windows*/}
        {this.renderDeleteConfirmWindow()}
        {this.renderTeamFormModal()}
        {this.renderDeleteAlertWindow()}
        <div className="row">
          <div className="col-md-12">{this.renderGrid()}</div>
        </div>
      </div>
    );
  }
}
