import axios from 'axios';
import * as propz from 'propz';
import { AppUser } from 'Src/views/App/App';
import { DEFAULT_SKIP } from '../../../consts/table';
import { UserProfile } from '../../../models/profile';
import { clearSessions } from '../../session/session';
import { Sport } from '../../../models/sport';
import { SchoolForm } from '../../../models/form';
import { SchoolHouse } from '../../../models/house';
/** CONST */
export const DEFAULT_FILTER = {
  limit: 10,
  skip: DEFAULT_SKIP
};

/** USER */
export function getRoles(user: AppUser) {
  const key = user.loginSessionKey;

  const config = {
    headers: { usid: key }
  };

  return axios.get(`${window.apiBase}/i/roles`, config).then(
    response => {
      return response.data;
    },
    err => {
      const status = propz.get(err, ['response', 'status']);
      if (status === 401) {
        clearSessions();
        //fix it later
        document.location.reload();
      } else {
        throw err;
      }
    }
  );
}

export function getProfile(user: AppUser) {
  const key = user.loginSessionKey;

  const config = {
    headers: { usid: key }
  };

  return axios.get(`${window.apiBase}/i/profile`, config).then(response => {
    return response.data;
  });
}

export function setProfile(user: AppUser, profile: UserProfile) {
  const key = user.loginSessionKey;

  const config = {
    headers: { usid: key }
  };

  return axios.put(`${window.apiBase}/i/profile`, profile, config).then(response => {
    return response.data;
  });
}

export function deleteUser(user: AppUser) {
  const key = user.loginSessionKey;

  const config = {
    headers: { usid: key }
  };

  return axios.post(`${window.apiBase}/i/profile/wipe`, {}, config).then(response => {
    return response.data;
  });
}

export function getSchool(user: AppUser, schoolId: string, optKey?: string) {
  const key = user.roleSessionKey;

  let config;
  if (typeof optKey !== 'undefined') {
    config = {
      headers: { usid: optKey }
    };
  } else {
    config = {
      headers: { usid: key }
    };
  }

  return axios.get(`${window.apiBase}/i/schools/${schoolId}`, config).then(
    response => {
      return response.data;
    },
    err => {
      const status = propz.get(err, ['response', 'status']);
      if (status === 401) {
        clearSessions();
        //fix it later
        document.location.reload();
      } else {
        throw err;
      }
    }
  );
}

export function getSchools(user: AppUser, filter = DEFAULT_FILTER) {
  const { roleSessionKey: key } = user;

  const config = {
    headers: { usid: key },
    params: {
      filter
    }
  };

  return axios.get(`${window.apiBase}/i/schools`, config).then(response => {
    return response.data;
  });
}

/** FORMS AND HOUSES */
export function getSchoolForms(user: AppUser, filter = DEFAULT_FILTER): Promise<SchoolForm[]> {
  const { roleSessionKey: key, activeSchoolId } = user;

  const config = {
    headers: { usid: key },
    params: {
      filter
    }
  };

  return axios.get<SchoolForm[]>(`${window.apiBase}/i/schools/${activeSchoolId}/forms`, config).then(response => {
    return response.data;
  });
}

export function getSchoolHouses(user: AppUser, filter = DEFAULT_FILTER): Promise<SchoolHouse[]> {
  const { roleSessionKey: key, activeSchoolId } = user;

  const config = {
    headers: { usid: key },
    params: {
      filter
    }
  };

  return axios.get<SchoolHouse[]>(`${window.apiBase}/i/schools/${activeSchoolId}/houses`, config).then(response => {
    return response.data;
  });
}

export function getSchoolSports(user: AppUser, filter = DEFAULT_FILTER): Promise<Sport[]> {
  const { roleSessionKey: key, activeSchoolId } = user;

  const config = {
    headers: { usid: key },
    params: {
      filter
    }
  };

  return axios.get<Sport[]>(`${window.apiBase}/i/schools/${activeSchoolId}/sports`, config).then(response => {
    return response.data;
  });
}

export function getSchoolClubSports(user: AppUser, filter = DEFAULT_FILTER): Promise<Sport[]> {
  const { roleSessionKey: key, activeSchoolId } = user;

  const config = {
    headers: { usid: key },
    params: {
      filter
    }
  };

  return axios.get<Sport[]>(`${window.apiBase}/i/schools/${activeSchoolId}/clubSports`, config).then(response => {
    return response.data;
  });
}

export function getSchoolVenues(user: AppUser, filter = DEFAULT_FILTER) {
  const { roleSessionKey: key, activeSchoolId } = user;

  const config = {
    headers: { usid: key },
    params: {
      filter
    }
  };

  return axios.get(`${window.apiBase}/i/schools/${activeSchoolId}/places`, config).then(response => {
    return response.data;
  });
}

export function getPlacesAndPostcodes(user: AppUser, filter = DEFAULT_FILTER) {
  const { roleSessionKey: key, activeSchoolId } = user;

  const config = {
    headers: { usid: key },
    params: {
      filter
    }
  };

  return axios.get(`${window.apiBase}/i/schools/${activeSchoolId}/placesAndPostcodes`, config).then(response => {
    return response.data;
  });
}

export function changePhone(user: AppUser | { loginSessionKey: string }, phone: string) {
  const { loginSessionKey: key } = user;

  const config = {
    headers: { usid: key }
  };

  return axios.put(`${window.apiBase}/i/profile/phone`, { phone: phone }, config).then(response => {
    return response.data;
  });
}

export function resendPhoneCode(user: AppUser | { loginSessionKey: string }) {
  const { loginSessionKey: key } = user;

  const config = {
    headers: { usid: key }
  };

  return axios.post(`${window.apiBase}/i/profile/phone/resend`, {}, config).then(response => {
    return response.data;
  });
}

export function changeEmail(user: AppUser | { loginSessionKey: string }, email: string) {
  const { loginSessionKey: key } = user;

  const config = {
    headers: { usid: key }
  };

  return axios.put(`${window.apiBase}/i/profile/email`, { email: email }, config).then(response => {
    return response.data;
  });
}

export function resendEmailCode(user: AppUser | { loginSessionKey: string }) {
  const { loginSessionKey: key } = user;

  const config = {
    headers: { usid: key }
  };

  return axios.post(`${window.apiBase}/i/profile/email/resend`, {}, config).then(response => {
    return response.data;
  });
}

export function getPermissions(user: AppUser, filter = DEFAULT_FILTER) {
  const { loginSessionKey: key } = user;

  const config = {
    headers: { usid: key },
    params: {
      filter
    }
  };

  return axios.get(`${window.apiBase}/i/permissions`, config).then(response => {
    return response.data;
  });
}

export function deletePermission(user: AppUser, permissionId: string) {
  const { loginSessionKey: key } = user;

  const config = {
    headers: { usid: key }
  };

  return axios.delete(`${window.apiBase}/i/permissions/${permissionId}`, config).then(response => {
    return response.data;
  });
}

export function getProfileRequests(user: AppUser, filter = DEFAULT_FILTER) {
  const { loginSessionKey: key } = user;

  const config = {
    headers: { usid: key },
    params: {
      filter
    }
  };
  return axios.get(`${window.apiBase}/i/permissions/requests`, config).then(response => {
    return response.data;
  });
}

export function createProfileRequest(
  user: AppUser | { loginSessionKey: string },
  data: { preset: string; schoolId: string; comment?: string; details?: any }
) {
  const { loginSessionKey: key } = user;

  const config = {
    headers: { usid: key }
  };

  return axios.post(`${window.apiBase}/i/permissions/requests`, data, config).then(response => {
    return response.data;
  });
}

export function deleteProfileRequest(user: AppUser, requestId: string) {
  const { loginSessionKey: key } = user;

  const config = {
    headers: { usid: key }
  };
  return axios.delete(`${window.apiBase}/i/permissions/requests/${requestId}`, config).then(response => {
    return response.data;
  });
}

export function getNotificationsChannels(user: AppUser) {
  const { loginSessionKey: key } = user;

  const config = {
    headers: { usid: key }
  };

  return axios.get(`${window.apiBase}/i/notifications/channels`, config).then(response => {
    return response.data;
  });
}

export function createNotificationChannel(user: AppUser, data: { token: string; type: string }) {
  const { loginSessionKey: key } = user;

  const config = {
    headers: { usid: key }
  };

  return axios.post(`${window.apiBase}/i/notifications/channels`, data, config).then(response => {
    return response.data;
  });
}

export function deleteNotificationChannel(user: AppUser, notificationChannelId: string) {
  const { loginSessionKey: key } = user;

  const config = {
    headers: { usid: key }
  };

  return axios.delete(`${window.apiBase}/i/notifications/channels/${notificationChannelId}`, config).then(response => {
    return response.data;
  });
}
