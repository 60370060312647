import * as React from 'react';
import * as propz from 'propz';
import { Field, Form, Formik } from 'formik';
import * as moment from 'moment';
import { Button } from 'Src/components/Button/Button';
import { getAgesForMultiSelect, getAgesFromMultiSelect } from '../../../../../../helpers/multiselect/multiselect';
import { SchoolForm } from '../../../../../../models/form';
import { LabelWithQuestionIcon } from '../../../../../../components/LabelWithQuestionIcon/LabelWithQuestionIcon';
import DatePicker from 'react-datepicker';
import { MultiSelectCheckboxes } from '../../../../../../components/MultiSelectCheckboxes/MultiSelectCheckboxes';
import { VenueForm } from 'Src/views/GenericView/AdminView/School/Venues/VenueForm/VenueForm';
import { SimpleModal } from '../../../../../../components/SimpleModal/SimpleModal';
import { Map } from 'Src/components/Map/Map';
import { TBD } from '../../../../../../consts/venue';
import { AppUser } from '../../../../../App/App';
import { createVenue } from 'Src/helpers/service/admin/venues';
import { Point } from '../../../../../../models/venue';
import { Autocomplete } from '../../../../../../components/Autocomplete/Autocomplete';
import {
  convertPlaceToPostcodeWithPlaceName,
  getDefaultPoint,
  getPostcodes,
  isPlace
} from '../../../../../../helpers/venue/venue';
import { Switch } from '../../../../../../components/Switch/Switch';
import { uploadImage } from '../../../../../../helpers/service/image';
import { getNameByPlaceOrPostcode } from '../../../../../../helpers/autocomplete/autocomplete';
import * as Yup from 'yup';
import './SchoolEventsEditSettingsForm.scss';
import { isClubEvent } from '../../../../../../helpers/event/event';
import { CLUB_EVENT_EDIT_MODE, EVENT_TYPES } from '../../../../../../consts/event';
import { SchoolEvent } from '../../../../../../models/event';
import { SchoolEventClubEventRadioButtons } from '../SchoolEventClubEventRadioButtons/SchoolEventClubEventRadioButtons';

interface Props {
  user: AppUser;
  schoolEvent: SchoolEvent;
  forms: SchoolForm[];
  onCancel: () => void;
  onSubmit: (data: any, editMode: string, reasonForChange: string) => void;
}

interface State {
  point: Point;
  venue: any;
  isDeleteVenueModalOpen: boolean;
  isCreateVenueFormOpen: boolean;
  isImageError: boolean;
  isChangeFollowingEvents: boolean;
  editMode: string;
  showChallengeSettings: boolean;
  reasonForChange: string;
  isReasonForChangeVisible: boolean;
}

export class SchoolEventsEditSettingsForm extends React.Component<Props, State> {
  constructor(props) {
    super(props);

    const { schoolEvent, user } = this.props;
    const venue = propz.get(schoolEvent, ['venue']);
    const convertPlace = typeof schoolEvent !== 'undefined' ? convertPlaceToPostcodeWithPlaceName(venue) : undefined;
    this.state = {
      point: propz.get(convertPlace, ['point'], getDefaultPoint(user)),
      venue: schoolEvent.venue,
      isDeleteVenueModalOpen: false,
      isCreateVenueFormOpen: false,
      isImageError: false,
      isChangeFollowingEvents: false,
      editMode: CLUB_EVENT_EDIT_MODE.SINGLE,
      showChallengeSettings: false,
      reasonForChange: '',
      isReasonForChangeVisible: false
    };
  }

  toggleChallengeSettings = () => {
    this.setState(prevState => ({
      showChallengeSettings: !prevState.showChallengeSettings
    }));
  };

  onAgesChange = (index, setFieldValue, values) => {
    const ageItems = values.ages;
    const ageItemsUpdated = ageItems.map((ageItem, ageIndex) => {
      return ageIndex === index
        ? {
            ...ageItem,
            isChecked: !ageItem.isChecked
          }
        : ageItem;
    });
    setFieldValue('ages', ageItemsUpdated);
  };

  onSubmitVenueForm = (data): void => {
    const { user } = this.props;

    createVenue(user, data).then(venue => {
      const convertPlace = convertPlaceToPostcodeWithPlaceName(venue);

      this.setState({
        venue: convertPlace,
        point: convertPlace.point,
        isCreateVenueFormOpen: false
      });
    });
  };

  onImageSelected = (event, setFieldValue) => {
    const image = event.target.files[0];
    const { user } = this.props;

    uploadImage(user, image)
      .then(response => {
        const picUrl = `${window.apiImg}/images/${response.key}`;
        setFieldValue('picUrl', picUrl);

        this.setState({
          isImageError: false
        });
      })
      .catch(error => {
        console.error(error);
        this.setState({ isImageError: true });
      });
  };

  onRemoveImageClick = (setFieldValue): void => {
    setFieldValue('picUrl', '');
  };

  getNewPoint = pointNew => {
    this.setState({
      point: pointNew
    });
  };

  onCancelCreateVenueFormOpenClick = (): void => {
    this.setState({
      isCreateVenueFormOpen: false
    });
  };

  createVenueFormOpen = (): void => {
    this.setState({
      isCreateVenueFormOpen: true
    });
  };

  onIsChangeFollowingEventsClick = () => {
    this.setState({
      isChangeFollowingEvents: true
    });
  };

  onEditModeClick = editMode => {
    this.setState({
      editMode: editMode
    });
  };

  handleFieldChange = (fieldName, setFieldValue, value) => {
    const { schoolEvent } = this.props;

    setFieldValue(fieldName, value);

    if (fieldName === 'startTime' && value !== moment(schoolEvent.startTime).toDate()) {
      this.setState({ isReasonForChangeVisible: true });
    } else if (fieldName === 'endTime' && value !== moment(schoolEvent.endTime).toDate()) {
      this.setState({ isReasonForChangeVisible: true });
    } else if (fieldName === 'venue' && value !== schoolEvent.venue) {
      this.setState({ isReasonForChangeVisible: true });
    }
  };

  checkForChanges = (field, value) => {
    const { schoolEvent } = this.props;
    const currentValue = propz.get(schoolEvent, [field]);
    const isReasonForChangeVisible = value !== currentValue;
    this.setState({ isReasonForChangeVisible });
  };

  renderCreateVenueForm = () => {
    const { user } = this.props;
    return (
      <SimpleModal isOpen={this.state.isCreateVenueFormOpen} title={'New venue'}>
        <VenueForm user={user} onCancel={this.onCancelCreateVenueFormOpenClick} onSubmit={this.onSubmitVenueForm} />
      </SimpleModal>
    );
  };

  render() {
    const { schoolEvent, forms, onSubmit, onCancel, user } = this.props;
    const {
      point,
      isChangeFollowingEvents,
      editMode,
      venue,
      showChallengeSettings,
      reasonForChange,
      isReasonForChangeVisible
    } = this.state;
    const startTimeDateObj = moment(schoolEvent.startTime).toDate();
    const endTimeDateObj = moment(schoolEvent.endTime).toDate();
    const { activeSchool } = user;

    const validationSchema = Yup.object().shape({
      leaguePositionsCount: Yup.number(),
      startTime: Yup.date().max(Yup.ref('endTime'), 'Must be less than end'),
      endTime: Yup.date().min(Yup.ref('startTime'), 'Must be more than start'),
      venue: Yup.string().test('venue', 'Required', function(value) {
        const isExistVenue = typeof venue !== 'undefined';
        return isExistVenue;
      }),
      replyToEmail: Yup.string()
        .nullable()
        .email('Invalid email format')
    });

    const eventSettingForm = {
      startTime: startTimeDateObj,
      endTime: endTimeDateObj,
      ages: getAgesForMultiSelect(activeSchool, forms, schoolEvent.ages),
      venue: venue,
      picUrl: propz.get(schoolEvent, ['photos', '0', 'picUrl'], ''),
      isStudentCanSubmitResult: schoolEvent.isStudentCanSubmitResult,
      isStudentCanJoin: schoolEvent.isStudentCanJoin,
      leaguePositionsCount: schoolEvent.leaguePositionsCount,
      shortDescription: propz.get(schoolEvent, ['eventDetails', 'shortDescription'], ''),
      replyToEmail: propz.get(schoolEvent, ['replyToEmail'], '')
    };

    return (
      <div className="container-fluid">
        {this.renderCreateVenueForm()}
        <div className="row">
          <div className="col-md-12">
            <Formik
              initialValues={eventSettingForm}
              validationSchema={validationSchema}
              onSubmit={values => {
                const { ages, picUrl, ...rest } = values;
                const newVenue = { ...venue };
                if (typeof point !== 'undefined') {
                  newVenue.point = { ...point };
                }

                const dataToSubmit = {
                  ...rest,
                  venue: newVenue,
                  ages: getAgesFromMultiSelect(activeSchool, forms, ages),
                  photos: [typeof picUrl !== 'undefined' && picUrl.length > 0 ? { picUrl: picUrl } : {}],
                  reasonForChange: this.state.reasonForChange
                };

                if (isClubEvent(schoolEvent) && !isChangeFollowingEvents) {
                  this.onIsChangeFollowingEventsClick();
                } else {
                  onSubmit(dataToSubmit, editMode, reasonForChange);
                }
              }}
              render={({ values, setFieldValue, touched, errors }) => (
                <Form>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group mt-3">
                        <LabelWithQuestionIcon labelText="Start time" hintText="" />
                        <Field
                          name="startTime"
                          render={({ field }) => (
                            <div className="mb-3">
                              <DatePicker
                                selected={field.value}
                                onChange={date => this.handleFieldChange('startTime', setFieldValue, date)}
                                className="form-control"
                                dateFormat={'dd-MM-yyyy HH:mm'}
                                showTimeSelect
                                timeFormat="HH:mm"
                                timeIntervals={5}
                                timeCaption="time"
                              />
                            </div>
                          )}
                        />
                        {touched.startTime && errors.startTime ? (
                          <div className="alert alert-danger">{errors.startTime}</div>
                        ) : null}
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="form-group mt-3">
                        <LabelWithQuestionIcon labelText="Finish/Collection time" hintText="" />
                        <Field
                          name="endTime"
                          render={({ field }) => (
                            <div className="mb-3">
                              <DatePicker
                                selected={field.value}
                                onChange={date => this.handleFieldChange('endTime', setFieldValue, date)}
                                className="form-control"
                                dateFormat={'dd-MM-yyyy HH:mm'}
                                showTimeSelect
                                timeFormat="HH:mm"
                                timeIntervals={5}
                                timeCaption="time"
                              />
                            </div>
                          )}
                        />
                        {touched.endTime && errors.endTime ? (
                          <div className="alert alert-danger">{errors.endTime}</div>
                        ) : null}
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <LabelWithQuestionIcon
                          labelText="Short description"
                          hintText={
                            'By default, an event name is generated automatically based on the event settings. ' +
                            'Although, you may choose to add a Short Description, it will then be displayed across the platform. ' +
                            'It is particularly useful when an event is used for the individual challenges within your school as ' +
                            'you are able to give it a bright and attractive name to be shown to students/parents.'
                          }
                        />
                        <Field type="text" name="shortDescription" className="form-control" />
                      </div>
                    </div>
                  </div>

                  <div className="form-group">
                    <LabelWithQuestionIcon labelText="Ages" hintText="" />
                    <MultiSelectCheckboxes
                      items={values.ages}
                      cols={3}
                      onChange={index => this.onAgesChange(index, setFieldValue, values)}
                      customClass="mSchoolEventMultiSelect pt-0"
                    />
                  </div>

                  <div className="form-group">
                    <LabelWithQuestionIcon labelText="Venue / Location" hintText="" />
                    <Field
                      name="venue"
                      render={({ field }) => (
                        <Autocomplete
                          searchFunction={text => {
                            const { eventType, invitedSchools, inviterSchool } = schoolEvent;
                            const isExternalSchoolsType = eventType === EVENT_TYPES.EXTERNAL_SCHOOLS;
                            const participants = [...invitedSchools];

                            participants.push(inviterSchool);

                            return getPostcodes(text, user, isExternalSchoolsType, participants);
                          }}
                          getElementTitle={getNameByPlaceOrPostcode}
                          customClass="mFullWidth mb-3"
                          placeholder="Start typing the venue name"
                          defaultItem={this.state.venue}
                          onSelect={venue => {
                            let postcode = venue;
                            if (isPlace(postcode)) {
                              postcode = convertPlaceToPostcodeWithPlaceName(postcode);
                            }
                            setFieldValue('venue', postcode);
                            this.setState({
                              venue: postcode
                            });
                            this.getNewPoint(postcode.point);
                            this.checkForChanges('venue', postcode);
                          }}
                        />
                      )}
                    />
                    {touched.venue && errors.venue ? <div className="alert alert-danger">{errors.venue}</div> : null}
                  </div>
                  <div className="form-group">
                    <button type="button" className={'btn btn-success'} onClick={this.createVenueFormOpen}>
                      Create venue
                    </button>
                  </div>
                  <div className="form-group">
                    {propz.get(values.venue, ['venueType']) !== 'TBD' && typeof point !== 'undefined' && (
                      <Map
                        point={point}
                        getNewPoint={this.getNewPoint}
                        customStylingClass="eSchoolEventVenueMap"
                        isMarkerDraggable={true}
                      />
                    )}
                  </div>

                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <LabelWithQuestionIcon
                          labelText="Reply To Email"
                          hintText={
                            'Specify an email address to be used as the reply-to address for notifications related to this event. ' +
                            'This field is optional and if empty the default email address from your school settings will be used.'
                          }
                        />
                        <Field
                          type="email"
                          name="replyToEmail"
                          className="form-control"
                          placeholder="Enter reply-to email address"
                        />
                        {touched.replyToEmail && errors.replyToEmail ? (
                          <div className="alert alert-danger">{errors.replyToEmail}</div>
                        ) : null}
                      </div>
                    </div>
                  </div>

                  {!isClubEvent(schoolEvent) && (
                    <>
                      <div className="container-fluid mt-3 mb-3">
                        <div className="row">
                          <div className="col-md-12">
                            <div className="eChallengeSettingsLegend">
                              <LabelWithQuestionIcon
                                labelText="Challenge settings"
                                hintText={
                                  'Please only use this section if you are setting up a challenge for an individual league'
                                }
                              />
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="container-fluid mb-3">
                        <div className="row">
                          <div className="col-md-12 bChallengeSettings">
                            <div className="form-group mt-4">
                              <LabelWithQuestionIcon labelText="Picture" hintText={''} />
                              <input
                                type="file"
                                name="picUrl"
                                className={'form-control-file'}
                                onChange={event => this.onImageSelected(event, setFieldValue)}
                              />
                            </div>
                            {values.picUrl && (
                              <div style={{ maxWidth: '200px' }}>
                                <button
                                  type="button"
                                  className="close"
                                  onClick={() => this.onRemoveImageClick(setFieldValue)}
                                >
                                  <span>&times;</span>
                                </button>
                                <img className="img-fluid img-thumbnail" src={values.picUrl} alt="Event picture" />
                              </div>
                            )}

                            <div className="mb-3">
                              <div className="row">
                                <div className="col-md-5">
                                  <div className="form-group mt-2">
                                    <LabelWithQuestionIcon
                                      labelText="No. leaderboard positions"
                                      hintText={
                                        'Here you can set the number of positions shown in the leader board ' +
                                        'section of the student challenges mobile app and on the public league website'
                                      }
                                    />
                                    <Field type="text" name="leaguePositionsCount" className="form-control mb-3" />
                                    {touched.leaguePositionsCount && errors.leaguePositionsCount ? (
                                      <div className="alert alert-danger">{errors.leaguePositionsCount}</div>
                                    ) : null}
                                  </div>
                                </div>

                                <div className="col-md-7">
                                  <div className="form-check eStudentCanSubmitResult">
                                    <div className="d-flex">
                                      <Field
                                        name="isStudentCanSubmitResult"
                                        render={({ field }) => (
                                          <Switch
                                            {...field}
                                            value={values.isStudentCanSubmitResult}
                                            customClass="mb-3"
                                            onChange={event => {
                                              const isStudentCanSubmitResult = event.target.checked;
                                              setFieldValue('isStudentCanSubmitResult', isStudentCanSubmitResult);
                                            }}
                                          />
                                        )}
                                      />
                                      <LabelWithQuestionIcon
                                        labelText="Students can submit results"
                                        hintText={
                                          "In turning on the 'Students can submit results' feature, you are enabling" +
                                          ' students to enter their own results in the format allowed by the activity you have selected.' +
                                          ' Please note only use this feature if you are creating an individual challenge for students ' +
                                          'to join themselves'
                                        }
                                        customLabelClass="form-check-label pl-2"
                                        labelHtmlFor="isStudentCanSubmitResult"
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="mb-3">
                              <div className="row">
                                <div className="col-md-12">
                                  <div className="form-check eStudentCanJoin">
                                    <div className="d-flex">
                                      <Field
                                        name="isStudentCanJoin"
                                        render={({ field }) => (
                                          <Switch
                                            {...field}
                                            value={values.isStudentCanJoin}
                                            customClass="mb-3"
                                            onChange={event => {
                                              const isStudentCanJoin = event.target.checked;
                                              setFieldValue('isStudentCanJoin', isStudentCanJoin);
                                            }}
                                          />
                                        )}
                                      />
                                      <LabelWithQuestionIcon
                                        labelText="Students can join"
                                        hintText={''}
                                        customLabelClass="form-check-label pl-2"
                                        labelHtmlFor="isStudentCanJoin"
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  )}

                  {isReasonForChangeVisible && user.activeSchool.isEventUpdateNotificationCustomMessageEnabled && (
                    <div className="container-fluid mt-3 mb-3">
                      <div className="row">
                        <div className="col-md-12">
                          <div className="form-group">
                            <label htmlFor="reasonForChange">
                              <b>Reason for change (optional):</b>
                            </label>
                            <textarea
                              id="reasonForChange"
                              value={this.state.reasonForChange}
                              placeholder="Please provide a reason for the changes made to this event."
                              onChange={e => this.setState({ reasonForChange: e.target.value })}
                              className="form-control"
                              style={{ width: '100%', height: '100px', marginTop: '10px' }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  )}

                  {isChangeFollowingEvents && (
                    <div className="container-fluid mt-3 mb-3">
                      <div className="row">
                        <div className="col-md-12">
                          <SchoolEventClubEventRadioButtons
                            onEditModeClick={this.onEditModeClick}
                            editMode={editMode}
                          />
                        </div>
                      </div>
                    </div>
                  )}

                  <Button onClick={onCancel} text={'Cancel'} customClass={'mt-3 mb-3 mr-3 btn-secondary'} />
                  <button type="submit" className="mt-3 mb-3 btn btn-primary">
                    Save
                  </button>
                </Form>
              )}
            />
          </div>
        </div>
      </div>
    );
  }
}
