import * as React from 'react';
import { Field, Form, Formik } from 'formik';
import { SchoolUserPermission } from '../../../../../../models/schoolUser';
import * as propz from 'propz';
import {
  ALL_SCHOOL_ROLES_ADDING_LIST,
  MAX_USER_PRIORITY,
  MIN_USER_PRIORITY,
  ROLE,
  ROLE_SERVER_TO_CLIENT_MAPPING
} from '../../../../../../consts/user';
import { LabelWithQuestionIcon } from '../../../../../../components/LabelWithQuestionIcon/LabelWithQuestionIcon';
import { Autocomplete } from '../../../../../../components/Autocomplete/Autocomplete';
import {
  getFullName,
  getName,
  searchFunctionForms,
  searchFunctionHouses,
  searchFunctionSchoolStudents,
  searchFunctionSportsNinSportIds
} from '../../../../../../helpers/autocomplete/autocomplete';
import { AppUser } from '../../../../../App/App';
import * as Yup from 'yup';

interface Props {
  user: AppUser;
  userPermissions: SchoolUserPermission[];
  onCloseClick: () => void;
  onSubmit: (values) => void;
}

interface State {
  form: any;
  house: any;
}

export class UserAddRoleForm extends React.Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      form: undefined,
      house: undefined
    };
  }
  renderRoleOptions = (): React.ReactNode => {
    const { userPermissions, user } = this.props;
    const existedRoles = userPermissions.map(role => role.preset);
    const allRoles = [...ALL_SCHOOL_ROLES_ADDING_LIST];
    const roleOptions = allRoles
      .filter(role => role === ROLE.PARENT || existedRoles.every(existedRole => existedRole !== role))
      .map(role => (
        <option key={role} value={role}>
          {ROLE_SERVER_TO_CLIENT_MAPPING[role]}
        </option>
      ));

    return roleOptions;
  };

  getForms = text => {
    if (text === '') {
      this.setState({
        form: undefined
      });
    }
    const { user } = this.props;
    return searchFunctionForms(user, text);
  };

  getHouses = text => {
    if (text === '') {
      this.setState({
        house: undefined
      });
    }
    const { user } = this.props;
    return searchFunctionHouses(user, text);
  };

  getSports = (text: string, sportIds) => {
    const { user } = this.props;

    return searchFunctionSportsNinSportIds(user, text, sportIds);
  };

  getSchoolStudents = (text, setFieldValue) => {
    const { form, house } = this.state;
    if (text === '') {
      setFieldValue('studentId', '');
      setFieldValue('student', undefined);
      this.setState({
        house: undefined,
        form: undefined
      });
    }
    const user = this.props.user;

    return searchFunctionSchoolStudents(user, form, house, text);
  };

  renderPriorityOptions = (): React.ReactNode => {
    let priorityOptions = [];

    for (let index = MIN_USER_PRIORITY; index <= MAX_USER_PRIORITY; index++) {
      priorityOptions.push(
        <option key={index} value={index}>
          {index}
        </option>
      );
    }
    return priorityOptions;
  };

  render() {
    const roleInitial = {
      preset: '',
      sports: [],
      sportIds: [],
      priority: '0',
      student: undefined,
      studentId: ''
    };

    const RoleSchema = Yup.object().shape({
      preset: Yup.string().required('Required'),
      sportIds: Yup.array().test('sportIds', 'Required', function(value) {
        const isNoTeacherOrCoachRole = this.parent.preset !== ROLE.TEACHER && this.parent.preset !== ROLE.COACH;
        const isExistSportIds = value.length > 0;
        return isNoTeacherOrCoachRole || (isExistSportIds && !isNoTeacherOrCoachRole);
      }),
      studentId: Yup.string().test('studentId', 'Required', function(value) {
        const isNoParentRole = this.parent.preset !== ROLE.PARENT;
        const isExistStudentId = typeof value !== 'undefined' && value !== '';
        return isNoParentRole || (isExistStudentId && !isNoParentRole);
      })
    });

    return (
      <Formik
        onSubmit={values => {
          this.props.onSubmit(values);
        }}
        validateOnBlur={false}
        validateOnChange={false}
        initialValues={roleInitial}
        validationSchema={RoleSchema}
        render={({ touched, errors, values, setFieldValue }) => {
          const isTeacherOrCoachRole = values.preset === ROLE.TEACHER || values.preset === ROLE.COACH;
          const isParentRole = values.preset === ROLE.PARENT;
          const { form, house } = this.state;
          return (
            <Form>
              <div className="form-group">
                <LabelWithQuestionIcon labelText="Role" hintText={''} />
                <Field
                  component="select"
                  name="preset"
                  className="form-control mb-3"
                  onChange={event => {
                    const currentRole = event.target.value;
                    setFieldValue('preset', currentRole);

                    if (currentRole !== ROLE.TEACHER && currentRole !== ROLE.COACH) {
                      setFieldValue('preset', currentRole);
                      setFieldValue('sports', []);
                      setFieldValue('sportIds', []);
                    }
                  }}
                >
                  <option value={''} />
                  {this.renderRoleOptions()}
                </Field>
                {touched.preset && errors.preset ? <div className="alert alert-danger">{errors.preset}</div> : null}
              </div>

              {isParentRole && (
                <>
                  <div className="form-group">
                    <LabelWithQuestionIcon labelText="Priority" hintText={''} />
                    <Field component="select" name="priority" className="form-control mb-3">
                      <option key={`empty_priority`} value={0} />
                      {this.renderPriorityOptions()}
                    </Field>
                    {touched.priority && errors.priority ? (
                      <div className="alert alert-danger">{errors.priority}</div>
                    ) : null}
                  </div>

                  <div className="form-group">
                    <LabelWithQuestionIcon labelText="Form" hintText={''} />
                    <Field
                      name={'form'}
                      render={({ field }) => {
                        return (
                          <Autocomplete
                            searchFunction={this.getForms}
                            getElementTitle={getName}
                            customClass="mFullWidth mb-3"
                            defaultItem={form}
                            onSelect={form => {
                              this.setState({
                                form: form
                              });
                            }}
                          />
                        );
                      }}
                    />
                  </div>
                  <div className="form-group">
                    <LabelWithQuestionIcon labelText="House" hintText={''} />
                    <Field
                      name={'house'}
                      render={({ field }) => {
                        return (
                          <Autocomplete
                            searchFunction={this.getHouses}
                            getElementTitle={getName}
                            customClass="mFullWidth mb-3"
                            defaultItem={house}
                            onSelect={house => {
                              this.setState({
                                house: house
                              });
                            }}
                          />
                        );
                      }}
                    />
                  </div>
                  <div className="form-group">
                    <LabelWithQuestionIcon labelText="Student" hintText={''} />
                    <Field
                      name={'studentId'}
                      render={({ field }) => {
                        return (
                          <Autocomplete
                            searchFunction={text => this.getSchoolStudents(text, setFieldValue)}
                            getElementTitle={getFullName}
                            customClass="mFullWidth mb-3"
                            defaultItem={...values.student}
                            onSelect={student => {
                              setFieldValue('studentId', student.id);
                              setFieldValue('student', student);
                              const form = propz.get(student, ['form']);
                              const house = propz.get(student, ['house']);
                              this.setState({
                                form: form,
                                house: house
                              });
                            }}
                          />
                        );
                      }}
                    />
                    {touched.studentId && errors.studentId ? (
                      <div className="alert alert-danger">{errors.studentId}</div>
                    ) : null}
                  </div>
                </>
              )}

              {isTeacherOrCoachRole && (
                <div className="form-group">
                  <LabelWithQuestionIcon labelText="Sport" hintText={''} />
                  <div>
                    {values.sports.map((sport, index) => (
                      <span
                        key={`sport_tag_${sport.id}`}
                        className="badge badge-primary p-1 mr-1 mb-1 font-weight-normal"
                        style={{ fontSize: '14px' }}
                      >
                        {sport.name}
                        <span
                          aria-hidden="true"
                          className="ml-2"
                          style={{ cursor: 'pointer' }}
                          onClick={() => {
                            const ids = values.sportIds;
                            const sports = values.sports;

                            ids.splice(index, 1);
                            sports.splice(index, 1);
                            setFieldValue('sportIds', ids);
                            setFieldValue('sports', sports);
                          }}
                        >
                          &times;
                        </span>
                      </span>
                    ))}
                  </div>
                  <Field
                    name="sports"
                    render={({ field }) => {
                      const sportIds = values.sportIds;
                      const sports = values.sports;

                      return (
                        <Autocomplete
                          searchFunction={text => this.getSports(text, sportIds)}
                          getElementTitle={getName}
                          customClass="mFullWidth mb-3"
                          onSelect={sport => {
                            if (sportIds.findIndex(id => id === sport.id) === -1) {
                              sportIds.push(sport.id);
                              sports.push(sport);
                              setFieldValue('sportIds', sportIds);
                              setFieldValue('sports', sports);
                            }
                          }}
                        />
                      );
                    }}
                  />
                  {touched.sportIds && errors.sportIds ? (
                    <div className="alert alert-danger">{errors.sportIds}</div>
                  ) : null}
                </div>
              )}

              <button type="button" className="btn btn-secondary mr-3" onClick={this.props.onCloseClick}>
                Cancel
              </button>
              <button type="submit" className="btn btn-primary">
                Add
              </button>
            </Form>
          );
        }}
      />
    );
  }
}
